import {EpisodeIdWithDate, Series} from '../types';

const SEPARATOR: string = '|';

export function createEpisodeId(series: Series): string {
    const {season, episode} = series;
    return `s${season}_e${episode}`;
}

export function createEpisodeIdWithDate(series: Series, date: Date): string {
    if (!date) {
        return '';
    }
    return `${createEpisodeId(series)}${SEPARATOR}${date.getTime()}`;
}

export function getAirDate(episodeIdWithDate: string): EpisodeIdWithDate {
    if (episodeIdWithDate) {
        const splitResult: Array<string> = episodeIdWithDate.split(SEPARATOR);
        return {
            id: splitResult[0],
            date: new Date(Number(splitResult[1]))
        };
    }
    return null;
}
