export const MP_BUSINESS_CARD_PAGE: 'mp-business-cards-page' = 'mp-business-cards-page';
export const MP_SHOPPING_LIST_PAGE: 'mp-shopping-list-page' = 'mp-shopping-list-page';
export const MP_WISH_LIST = 'mp-wish-list-page';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [MP_BUSINESS_CARD_PAGE]: React.HTMLAttributes<HTMLElement>;
            [MP_SHOPPING_LIST_PAGE]: React.HTMLAttributes<HTMLDivElement>;
            [MP_WISH_LIST]: React.HTMLAttributes<HTMLDivElement>;
        }
    }
}
