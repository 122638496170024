import {Link} from 'react-router-dom';
import React from 'react';
import {normalizeString} from '@mp/common/utils/string';
import {Icon, SvgButton} from '@mp/common/svg';
import {isNotEmpty} from '@mp/common/utils/array';
import {createLinkToSearch} from '@mp/filmweb/utils/link';
import {Modal} from '@mp/common/modals/Modal';
import {getShareLink} from '../utils/getMessengerShareLink';
import {MovieInfo, Schedule} from '../types';

export interface MovieInfoSingleSchedule {
    movieInfo: MovieInfo;
    schedule?: Schedule;
}

interface ScheduleModalParams {
    selectedMovieInfo: MovieInfoSingleSchedule;
    date: Date;
    handleClose: () => void;
}

const DESCRIPTION: string = 'description';

export function ScheduleModal({selectedMovieInfo, date, handleClose}: ScheduleModalParams): JSX.Element {
    const {movieInfo, schedule} = selectedMovieInfo;
    const {title, synopsisShort, director, cast, runningTime} = movieInfo;
    const {startTime, endTime, filmStartTime, bookingLink, screenName} = schedule ?? {};

    return (
        <Modal handleClose={handleClose} title={title}>
            <div className="schedule-modal">
                <div className="screen">{screenName}</div>
                {startTime && endTime && (
                    <div className="schedule">
                        <span>{`${startTime} - ${endTime}`}</span>
                        <a href={getShareLink({date, id: normalizeString(title), startTime})}>
                            <SvgButton icon={Icon.Share} />
                        </a>
                    </div>
                )}

                {bookingLink && (
                    <div className="buy-ticket">
                        <a href={bookingLink}>
                            <span>KUP BILET</span>
                        </a>
                    </div>
                )}
                {filmStartTime && <div>Start filmu</div>}
                {filmStartTime && <div className={DESCRIPTION}>{filmStartTime}</div>}
                {runningTime && <div>Czas</div>}
                {runningTime && <div className={DESCRIPTION}>{runningTime} min.</div>}
                {director && <div>Reżyseria</div>}
                {director && <div className={DESCRIPTION}>{director}</div>}
                {isNotEmpty(cast) && (
                    <div className="cast">
                        <div>Obsada</div>
                        <div className={DESCRIPTION}>
                            {cast.map((person) => (
                                <Link key={person} to={createLinkToSearch(person)} target="_blank">
                                    {person}
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
                {synopsisShort && <div>Fabuła</div>}
                {synopsisShort && <div className={DESCRIPTION}>{synopsisShort}</div>}
            </div>
        </Modal>
    );
}
