import logo from '../assets/no-movie-image.png';

const FILMWEB_IMAGES_URL: string = 'https://fwcdn.pl/fpo';

export function getFullImagePath(imageLocation: string): string {
    if (imageLocation == null) {
        return logo;
    }
    return `${FILMWEB_IMAGES_URL}${imageLocation}`;
}
