import {addDay, isCurrentDay, resolveDayName} from '@mp/common/utils/date';
import {toDateString} from '@mp/common/utils/converters';
import {TabItem} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {Router} from '@mp/route';

export function getNavigationTabs(date: Date): Array<TabItem> {
    const prevDate: Date = isCurrentDay(date) ? date : addDay(date, -1);
    const nextDate: Date = addDay(date, 1);

    return [
        {name: '<', selected: false, url: isCurrentDay(date) ? null : Router.getUrlToCinemaProgramPage({date: prevDate})},
        {name: `${resolveLabelPrefix()}, ${toDateString(date, 'dd.mm')}`, selected: false, url: null},
        {name: '>', selected: false, url: Router.getUrlToCinemaProgramPage({date: nextDate})}
    ];

    function resolveLabelPrefix(): string {
        if (isCurrentDay(date)) {
            return 'Dzisiaj';
        }
        if (isCurrentDay(prevDate)) {
            return 'Jutro';
        }
        return resolveDayName(date);
    }
}
