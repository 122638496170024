import {ExpenseCategory} from '../types';

export function getNameByTypeId(id: number, expenseCategories: Array<ExpenseCategory>): string {
    const name: string = expenseCategories?.find((type) => type.id === id)?.name;
    if (name) {
        return name;
    }
    console.error(`Could not find type for id: "${id}"`);
    return 'unknown';
}
