import './_discountCardsPage.scss';
import React from 'react';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Button} from '@mp/common/components/button/ui/Button';
import {Icon, SvgButton} from '@mp/common/svg';
import {addDiscountCardService, loadDiscountCardsService} from './services';
import {Accordion, AccordionProps} from '../../components/accordion';
import {Loading} from '../../components/loading';
import {DiscountCard} from './types';

interface ShoppingCardState {
    isLoaded: boolean;
    items: Array<DiscountCard>;
    addWindowOpened: boolean;
}

export class DiscountCardsPage extends React.Component<{}, ShoppingCardState> {
    private inputTitle: React.RefObject<HTMLInputElement>;
    private inputNumber: React.RefObject<HTMLInputElement>;
    private inputOrder: React.RefObject<HTMLInputElement>;

    constructor(props: {}) {
        super(props);

        this.changeWindowOpenedState = this.changeWindowOpenedState.bind(this);
        this.addCard = this.addCard.bind(this);

        this.inputTitle = React.createRef<HTMLInputElement>();
        this.inputNumber = React.createRef<HTMLInputElement>();
        this.inputOrder = React.createRef<HTMLInputElement>();

        this.state = {
            isLoaded: false,
            items: [],
            addWindowOpened: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        loadDiscountCardsService().then(
            (res) => this.setState({items: res, isLoaded: true}),
            () => {
                this.setState({isLoaded: true});
                console.error('service error');
            }
        );
    }

    changeWindowOpenedState() {
        this.setState((prevState: ShoppingCardState) => {
            return {
                addWindowOpened: !prevState.addWindowOpened
            };
        });
    }

    addCard() {
        const title = this.inputTitle.current.value;
        const num: string = this.inputNumber.current.value;
        const sortOrder: number = Number(this.inputOrder.current.value || 0);

        addDiscountCardService({title, number: Number(num), sortOrder})
            .then(() => this.changeWindowOpenedState())
            .then(
                () => this.loadData(),
                (error) => console.error(error)
            );
    }

    render() {
        if (!this.state.isLoaded) {
            return <Loading />;
        }

        const accordions: Array<AccordionProps> = this.state.items.map((data: DiscountCard) => {
            const item: AccordionProps = {
                label: data.title,
                content: `<img src=data:image/gif;base64,${data.image} />`
            };
            return item;
        });

        return (
            <>
                <mp-discount-cards-page>
                    {this.state.addWindowOpened ? null : this.renderAddButton()}
                    {this.state.addWindowOpened ? this.renderAddCardWindow() : null}
                    {this.state.addWindowOpened ? null : <Accordion accordionRows={accordions} />}
                </mp-discount-cards-page>
            </>
        );
    }

    renderAddButton() {
        return (
            <div className="button-container">
                <SvgButton icon={Icon.Plus} onClick={this.changeWindowOpenedState} />
            </div>
        );
    }

    renderAddCardWindow() {
        return (
            <div className="add-card-window">
                <div className="title">
                    <SvgButton icon={Icon.Xmark} className={`exit`} onClick={this.changeWindowOpenedState} />
                    Panel dodawania nowej karty
                </div>
                <div>
                    <TextInput title="Nazwa" name="title" reference={this.inputTitle} />
                    <TextInput title="Numer kodu kreskowego" name="number" reference={this.inputNumber} />
                    <TextInput title="Lp." name="order" reference={this.inputOrder} />
                    <Button title="Dodaj" onClick={this.addCard} />
                </div>
                <div id="response"></div>
            </div>
        );
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'mp-discount-cards-page': React.HTMLAttributes<HTMLElement>;
        }
    }
}
