import {service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {Ingredient} from '../types';

const SERVICE_NAME: string = 'recipes.ingredients';

export function loadIngredientsService(): Promise<Array<Ingredient>> {
    return service.get<{ingredients: Array<Ingredient>}>(SERVICE_NAME).then(({data}) => data.ingredients);
}

export function addIngredientService(data: Ingredient): Promise<ServiceResponseWithId> {
    return service.post(SERVICE_NAME, {data});
}

export function editIngredientService(data: Ingredient): Promise<ServiceResponseWithAffectedRow> {
    return service.put(SERVICE_NAME, {data});
}
