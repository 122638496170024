import {AxiosResponse} from 'axios';
import {toDateString} from '@mp/common/utils/converters';
import {firstElement, isNotEmpty} from '@mp/common/utils/array';
import {service} from '@mp/common/api';
import {MovieInfo, MultikinoApiFilm, MultikinoApiShowingGroup, Schedule} from '../types';

const HTTPS_MULTIKINO: string = 'https://multikino.pl';

export function loadCinemaProgramService(date: Date): Promise<Array<MovieInfo>> {
    const dateString: string = toDateString(date, 'yyyy-mm-dd');
    const errors: Array<string> = [];

    return service.get('cinemaProgramByDate.load', {data: {date: dateString}}).then((response: AxiosResponse) => {
        const rawObjects: Array<MultikinoApiFilm> = response.data.program?.result;
        if (rawObjects == null) {
            return null;
        }

        const movieInfos: Array<MovieInfo> = [];

        rawObjects.forEach((film: MultikinoApiFilm) => {
            const showingGroup: MultikinoApiShowingGroup = firstElement(film.showingGroups);

            const schedules: Array<Schedule> = showingGroup.sessions
                ?.map(({startTime, endTime, bookingUrl, attributes, screenName}) => {
                    const expectedDateFragment: string = toDateString(date, 'yyyy-mm-dd');
                    if (!startTime.includes(expectedDateFragment)) {
                        errors.push(`Date error: expected "${expectedDateFragment}", got "${startTime}".`);
                    }
                    const startTimeDate: Date = new Date(startTime);

                    if (startTimeDate.getTime() < new Date().getTime()) {
                        return null;
                    }

                    const resolveFilmStartTime = (): string => {
                        if (endTime && film.runningTime) {
                            const startFilmTime: number = new Date(endTime).getTime() - film.runningTime * 60_000;
                            return toDateString(new Date(startFilmTime), 'HH:MM');
                        }
                        return null;
                    };

                    return {
                        startTime: toDateString(startTimeDate, 'HH:MM'),
                        endTime: toDateString(new Date(endTime), 'HH:MM'),
                        filmStartTime: resolveFilmStartTime(),
                        bookingLink: `${HTTPS_MULTIKINO}${bookingUrl}`,
                        screenName,
                        versionTitle: attributes
                            ?.map(({shortName}) => shortName)
                            .filter((value) => value.length < 10 && value.length > 1)
                            .join(', ')
                    };
                })
                .filter(Boolean);

            const filmParams: Array<string> = Array.from(film.genres) ?? [];
            if (film.runningTime) {
                filmParams.push(String(film.runningTime) + ' min');
            }

            const movieInfo: MovieInfo = {
                title: film.filmTitle,
                poster: film.posterImageSrc,
                filmUrl: film.filmUrl,
                filmParams: filmParams.join(' | '),
                promoLabels: [], // 'raw.PromoLabels?.names?.map((obj) => obj.short_name)',
                dayTitle: showingGroup.datePrefix || showingGroup.date,
                runningTime: film.runningTime,
                synopsisShort: film.synopsisShort,
                director: film.director,
                cast: film.cast?.split(',')?.filter(Boolean) ?? [],
                schedules
            };

            if (isNotEmpty(schedules)) {
                movieInfos.push(movieInfo);
            }
        });

        if (isNotEmpty(errors)) {
            alert(errors.join('\n'));
        }

        return movieInfos;
    });
}
