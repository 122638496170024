import {toNumber} from '@mp/common/utils/number';
import {service} from '@mp/common/api';
import {FilmRating} from '../types';

interface FilmRatingDTO {
    id: string;
    count: string;
    rate: string;
    countWantToSee: string;
}

export function loadFilmRatingService(movieId: string | number): Promise<FilmRating> {
    return service
        .get<FilmRatingDTO>('loadFilmRating', {data: {id: movieId}, prefix: 'filmweb'})
        .then((response) => resolveFilmRatingServiceResult(response.data));
}

function resolveFilmRatingServiceResult(rawResults: FilmRatingDTO): FilmRating {
    return {
        id: Number(rawResults.id),
        count: toNumber(rawResults.count) ?? 0,
        rate: +(toNumber(rawResults.rate) ?? 0).toPrecision(3),
        countWantToSee: toNumber(rawResults.countWantToSee)
    };
}
