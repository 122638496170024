import {isNotEmpty, lastElement} from '@mp/common/utils/array';
import {DateSection} from '../../types';

export function resolveDateSection(dateSections: Array<DateSection>): {dateSection: DateSection; sectionIndex: number} {
    if (isNotEmpty(dateSections)) {
        return {
            dateSection: lastElement(dateSections),
            sectionIndex: dateSections.length - 1
        };
    }
    return {
        dateSection: {start: new Date(2000, 0, 1), end: new Date(9999, 0, 1)},
        sectionIndex: null
    };
}
