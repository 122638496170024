import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {Employer} from '../types';

interface EmployerDto {
    id: string;
    name: string;
    short_name: string;
}

let cachedResponse: Array<Employer> = null;

export function loadEmployersService(): Promise<ServiceResponseWithError<Array<Employer>>> {
    if (cachedResponse) {
        return Promise.resolve({data: cachedResponse});
    }
    return service.get<Array<EmployerDto>>('workHours.loadEmployers').then((response: AxiosResponse<Array<EmployerDto>>) => {
        cachedResponse = resolveEmployers(response.data);
        return {data: cachedResponse};
    });
}

export function resolveEmployers(rawData: Array<EmployerDto>): Array<Employer> {
    return rawData.map((raw) => ({id: Number(raw.id), name: raw.name, shortName: raw.short_name}));
}
