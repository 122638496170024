import {service, ServiceResponseWithError} from '@mp/common/api';
import {Icon} from '@mp/common/svg';
import {Item, Category} from '../types';

export interface CategoryDto {
    id: string;
    name: string;
    icon: string;
}

export interface ItemDto {
    id: string;
    category_id: string;
    title: string;
    description: string;
    completion_date: string;
}

export function loadCategoriesService(): Promise<ServiceResponseWithError<Array<Category>>> {
    return Promise.all([
        service.get<Array<CategoryDto>>('bucketList.loadCategories'),
        service.get<Array<ItemDto>>('bucketList.loadAllItems')
    ]).then((response) => {
        const categoriesDto: Array<CategoryDto> = response[0].data;
        const itemsDto: Array<ItemDto> = response[1].data;

        const categories: Array<Category> = categoriesDto.map((categoryDto: CategoryDto) => {
            const filteredItems: Array<Item> = itemsDto
                .filter((f) => f.category_id === categoryDto.id)
                .map(
                    (dto): Item => ({
                        id: Number(dto.id),
                        categoryId: Number(dto.category_id),
                        title: dto.title,
                        description: dto.description,
                        completionDate: dto.completion_date
                    })
                );

            const newCategory: Category = {
                id: Number(categoryDto.id),
                icon: categoryDto.icon as Icon,
                name: categoryDto.name,
                items: {
                    itemsCount: filteredItems.length,
                    uncompleted: filteredItems.filter((item) => !item.completionDate),
                    completed: filteredItems.filter((item) => item.completionDate)
                }
            };
            return newCategory;
        });

        return {
            data: categories
        };
    });
}
