import {Icon} from '@mp/common/svg';
import {CATEGORY_ITEMS} from './consts';

export function resolveCategoryIconClass(categoryId: number): Icon {
    return CATEGORY_ITEMS.find((f) => f.id === categoryId)?.icon;
}

export function getUnknownCategory(): number {
    return 4;
}
