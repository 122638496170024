import {addMonthAndSetFirstDay, daysInMonth, daysInPreviousMonth, firstDayInMonth, isCurrentDay} from '@mp/common/utils/date';
import {GridDate} from './types';

export const WEEK_DAY_SYMBOLS: Array<string> = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb', 'Nd'];

const DAYS_IN_WEEK: number = 7;

export function getVisibleDatesInGrid(currentMonth: Date): Array<GridDate> {
    const previousDates: Array<GridDate> = getVisibleDatesInPreviousMonth(currentMonth).map((date) => ({date, monthType: 'previous'}));
    const currentDates: Array<GridDate> = getVisibleDatesInCurrentMonth(currentMonth).map((date) => ({date, monthType: 'current'}));
    const nextDates: Array<GridDate> = getVisibleDatesInNextMonth(currentMonth, previousDates.length + currentDates.length).map((date) => ({
        date,
        monthType: 'next'
    }));
    const grid: GridDate = currentDates.find(({date}) => isCurrentDay(date));
    if (grid) {
        grid.isCurrentDate = true;
    }

    return [...previousDates, ...currentDates, ...nextDates];
}

function getVisibleDatesInPreviousMonth(currentMonth: Date): Array<Date> {
    const dates: Array<Date> = [];
    const lastVisibleDay: number = daysInPreviousMonth(currentMonth);
    const prevDays: number = firstDayInMonth(currentMonth) - 1;
    const firstVisibleDay: number = lastVisibleDay - (prevDays - 1);
    const prevMonth: Date = addMonthAndSetFirstDay(currentMonth, -1);
    for (let i: number = firstVisibleDay; i <= lastVisibleDay; i++) {
        dates.push(new Date(prevMonth.getFullYear(), prevMonth.getMonth(), i));
    }
    return dates;
}

function getVisibleDatesInCurrentMonth(currentMonth: Date): Array<Date> {
    const dates: Array<Date> = [];
    for (let i: number = 1; i <= daysInMonth(currentMonth); i++) {
        dates.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i));
    }
    return dates;
}

function getVisibleDatesInNextMonth(currentMonth: Date, previousVisibleDaysCount: number): Array<Date> {
    const dates: Array<Date> = [];
    const expectedCells: number = Math.ceil(previousVisibleDaysCount / DAYS_IN_WEEK) * DAYS_IN_WEEK;
    const lastVisibleDay: number = expectedCells - previousVisibleDaysCount + DAYS_IN_WEEK;
    const nextMonth: Date = addMonthAndSetFirstDay(currentMonth, 1);
    for (let i: number = 1; i <= lastVisibleDay; i++) {
        dates.push(new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i));
    }
    return dates;
}
