import {service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {TRAVEL_PAYMENT_SERVICE} from './const';

export * from './loadTravelPayments.service';
export * from './loadTravels.service';

export function addTravelsService(data: object): Promise<ServiceResponseWithId> {
    return service.post(TRAVEL_PAYMENT_SERVICE, {data});
}

export function editTravelsService(data: object): Promise<ServiceResponseWithAffectedRow> {
    return service.put(TRAVEL_PAYMENT_SERVICE, {data});
}
