export enum SubscriptionType {
    MONTHLY = 'monthly',
    ANNUAL = 'annual'
}

export interface Subscription {
    id: number;
    name: string;
    date: Date;
    nextPaymentDate: Date;
    cost: number;
    constMonthly: number;
    constAnnual: number;
    type: SubscriptionType;
    daysToPayment: number;
    isActive: true;
}
