import {InputData} from '@mp/common/components/form-input/types';
import {MultiOption} from '../../../../components/multi-select/types';
import {Recipe, RecipeCategory} from '../../types';

export function getInputsData(allRecipeCategories: Array<RecipeCategory>, selectedRecipe?: Recipe): Array<InputData<Recipe>> {
    const {id, name, categoryIds, calories, description} = selectedRecipe ?? {};

    const inputs: Array<InputData<Recipe>> = [
        {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: name, validation: {minLength: 3}},
        {
            id: 'categoryIds',
            displayName: 'Kategoria',
            type: 'multi-select',
            selectOptions: resolveCategoryOptions(),
            defaultValue: categoryIds ?? [],
            validation: {minLength: 1}
        },
        {id: 'calories', displayName: 'Kalorie', type: 'text-number', defaultValue: calories},
        {id: 'description', displayName: 'Opis', type: 'text-area', defaultValue: description}
    ];

    if (id) {
        inputs.push({id: 'id', displayName: '', type: 'hidden', defaultValue: id});
    }

    return inputs;

    function resolveCategoryOptions(): Array<MultiOption> {
        return allRecipeCategories.map((x: RecipeCategory) => ({name: x.displayName, value: x.id + ''}));
    }
}
