import {IKnowCard, IKnowSubCard} from './types';

export function isCardDataCompleted(card: IKnowCard) {
    return (
        isSubCardDataCompleted(card.world) &&
        isSubCardDataCompleted(card.history) &&
        isSubCardDataCompleted(card.culture) &&
        isSubCardDataCompleted(card.people)
    );
}

export function isSubCardDataCompleted(s: IKnowSubCard): boolean {
    return !!s.question && !!s.answer && !!s.clue_1 && !!s.clue_2 && !!s.clue_3;
}
