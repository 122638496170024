import './_homePage.scss';
import {Navigate} from 'react-router';
import React from 'react';
import {Icon} from '@mp/common/svg';
import {tileItems, TileItemParams} from '../../components/header/tileItems';
import {TileItem} from './ui/components/TileItem';
import {getAppSettings} from '../../global';

const MP_HOME_PAGE: 'mp-home-page' = 'mp-home-page';

interface HomePageState {
    redirect: string;
}

export class HomePage extends React.Component<{}, HomePageState> {
    private homeItems: Array<TileItemParams> = [];

    constructor(props: {}) {
        super(props);

        this.state = {redirect: null};

        const {homeItems} = getAppSettings();

        const items: Array<TileItemParams> = homeItems.map((id) => tileItems.getAllTitleItems().find(({id: tileId}) => tileId === id));
        this.homeItems.push(...items);
    }

    public render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className={MP_HOME_PAGE}>
                <div className={`${MP_HOME_PAGE}-grid`}>{this.homeItems.map(this.renderTileItem)}</div>
            </div>
        );
    }

    private renderTileItem(item: TileItemParams): JSX.Element {
        if (item) {
            return <TileItem key={item.route} {...item} />;
        }
        return <TileItem id={999} svgIcon={Icon.Question} title={'Unknown'} route={'/'} />;
    }
}
