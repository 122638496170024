interface TI18n {
    add: string;
    description: string;
    editWindowTitle: string;
}

export const t: TI18n = {
    add: 'Dodaj',
    description: 'opis',
    editWindowTitle: 'Edytuj wydatek'
};
