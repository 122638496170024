interface ToCurrencyOptions {
    locales?: string;
    withPenny?: boolean;
    withSymbol?: boolean;
}

export function toCurrency(num: number, options?: ToCurrencyOptions): string {
    const {locales = 'pl', withSymbol = true, withPenny = true} = options ?? {};

    const [v1, v2] = num.toFixed(2).split('.');

    if (withPenny) {
        return `${Number(v1).toLocaleString(locales)},${v2}${withSymbol ? ' zł' : ''}`;
    }
    return `${Number(v1).toLocaleString(locales)}${withSymbol ? ' zł' : ''}`;
}

export function convertToCurrency(num: number, withCurrencySymbol: boolean = false): string {
    const [v1, v2] = num.toFixed(2).split('.');
    return `${Number(v1).toLocaleString('pl')},${v2}${withCurrencySymbol ? ' zł' : ''}`;
}

export function toNumber(value: unknown): number {
    if (value == null) {
        return null;
    }
    return Number(value);
}

export function round(x: number, decimalDigits: number): number {
    if (Number.isSafeInteger(decimalDigits) && decimalDigits > 0 && decimalDigits < 10) {
        const n: number = Math.pow(10, decimalDigits);
        return Math.round(x * n) / n;
    } else {
        throw new Error('round error');
    }
}

export function minDigits(n: number, _minDigits: number = 2): string {
    const prefixZeroCount: number = _minDigits - (n + '').length;
    if (prefixZeroCount <= 0) {
        return n + '';
    }
    return '0'.repeat(prefixZeroCount) + n;
}
