import {createContext} from 'react';

export interface ICommonContext {
    showCalendarNearDates: boolean;
}

export const CommonContext = createContext({
    context: {
        showCalendarNearDates: true
    } as ICommonContext,
    setContext: (context: ICommonContext): void => {
        //
    }
});
