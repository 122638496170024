import React from 'react';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import {Icon, SvgButton} from '@mp/common/svg';
import {round} from '@mp/common/utils/number';
import {FilmRating} from '@mp/filmweb/types';

interface RatingBoxParams {
    filmRating: FilmRating;
}
export function RatingBox(params: RatingBoxParams): JSX.Element {
    const {id, rate, count} = params?.filmRating ?? {};
    if (!rate) {
        return null;
    }

    return (
        <div className="rating-box">
            <SvgButton icon={Icon.StarFilled} onClick={() => window.open(createLinkToMovie(id))} />
            <span>
                {round(rate, 1).toFixed(1)} ({count} ocen)
            </span>
        </div>
    );
}
