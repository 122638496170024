import {Router} from '@mp/route';
import {TabItem} from '../../../../components/tabs-navigation/ui/TabsNavigation';
import {Employer} from '../../types';

export function getNavigationTabs(employers: Array<Employer>, employerId: number, onTabClick: () => void): Array<TabItem> {
    return employers.map((employer: Employer) => {
        const tabItem: TabItem = {
            name: employer.shortName,
            selected: employer.id === employerId,
            url: Router.getUrlToWorkHoursPage(employer.id),
            onClick: onTabClick
        };
        return tabItem;
    });
}
