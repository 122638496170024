import {AxiosResponse} from 'axios';
import {toDate} from '@mp/common/utils/converters';
import {service} from '@mp/common/api';
import {isEmpty} from '@mp/common/utils/array';
import {Debt, DebtDTO, Debtor} from '../types';

export function loadDebtorsService(): Promise<Array<Debtor>> {
    return service.get<Array<DebtDTO>>('debtors').then((result: AxiosResponse<Array<DebtDTO>>) => resolveDebtors(result.data));
}

function resolveDebtors(rawDebtors: Array<DebtDTO>): Array<Debtor> {
    if (isEmpty(rawDebtors)) {
        return [];
    }

    const debts: Array<Debt> = rawDebtors.map(
        (raw): Debt => ({
            id: raw.id,
            value: raw.value,
            name: raw.name,
            description: raw.description,
            dateFrom: toDate(raw.date_from)
        })
    );

    const debtors: Array<Debtor> = [];

    debts.forEach((debt) => {
        const foundDebtor: Debtor = debtors.find((debtor) => debtor.name === debt.name);
        if (foundDebtor) {
            foundDebtor.debts.push(debt);
        } else {
            debtors.push({
                date: debt.dateFrom,
                name: debt.name,
                debts: [debt]
            });
        }
    });

    return debtors;
}
