import classNames from 'classnames';
import React from 'react';
import {useState} from 'react';
import {SelectSingleCategoryItem} from '../types';
import {SvgButton} from '../../../svg';

interface SelectSingleIconProps {
    defaultCategoryId: number;
    onItemSelected: (id: number) => void;
    items: Array<SelectSingleCategoryItem>;
    title?: string;
    disabled?: boolean;
    showLabel?: boolean;
}

export function SelectSingleIcon(props: SelectSingleIconProps): JSX.Element {
    const {disabled, title, showLabel} = props;
    const [selectedId, setSelectedId] = useState(props.defaultCategoryId);

    const fullTitle: string = !showLabel ? title : `${title} (${props.items.find((f) => selectedId === f.id)?.label?.toLowerCase()})`;

    return (
        <div className="select-single-icon">
            {title && <div className="select-single-icon-title">{fullTitle}</div>}
            {props.items.map((item: SelectSingleCategoryItem) => {
                const itemClass: string = classNames({selected: Number(selectedId) === Number(item.id), 'select-item-disabled': disabled});
                return <SvgButton key={item.id} icon={item.icon} className={itemClass} onClick={() => onIconClick(item.id)} />;
            })}
        </div>
    );

    function onIconClick(itemId: number): void {
        if (!disabled) {
            props.onItemSelected(itemId);
            setSelectedId(itemId);
        }
    }
}
