import {AxiosResponse} from 'axios';
import {toDate} from '@mp/common/utils/converters';
import {service} from '@mp/common/api';
import {TRAVEL_PAYMENT_SERVICE} from './const';

export interface LoadTravelsServiceResult {
    travels: Array<TravelData>;
}

interface TravelsPlacesDTO {
    places: Array<TravelsPlacesDTO>;
}
interface TravelsPlacesDTO {
    id: number;
    name: string;
    start: string;
    end: string;
}

export interface TravelData {
    id: number;
    name: string;
    start: Date;
    end: Date;
}

export function loadTravelsService(): Promise<LoadTravelsServiceResult> {
    return service
        .get<TravelsPlacesDTO>(TRAVEL_PAYMENT_SERVICE)
        .then((result: AxiosResponse<TravelsPlacesDTO>) => resolveTravels(result.data));
}

function resolveTravels(rawTravelsData: TravelsPlacesDTO): LoadTravelsServiceResult {
    return {
        travels: rawTravelsData?.places?.map((rawTravel) => ({
            id: rawTravel.id,
            name: rawTravel.name,
            start: toDate(rawTravel.start),
            end: toDate(rawTravel.end)
        }))
    };
}
