import React from 'react';
import {convertToCurrency} from '@mp/common/utils/number';
import {getValuesForTypes, ValuesForTypesMap} from '../utils/getValuesForTypes';
import {getNameByTypeId} from '../utils/getNameByTypeId';
import {Expense, ExpenseCategory} from '../types';

interface ExpensesSummaryProps {
    expenses: Array<Expense>;
    expenseCategories: Array<ExpenseCategory>;
    title: string | number;
    onExpenseCategoryClick?: (id: number) => void;
}

export function ExpensesSummary(props: ExpensesSummaryProps): JSX.Element {
    const {expenses, expenseCategories, title, onExpenseCategoryClick} = props;
    const elements: Array<JSX.Element> = [];

    const valuesForTypesMap: ValuesForTypesMap = getValuesForTypes(expenses, expenseCategories);
    let sum: number = 0;

    valuesForTypesMap.forEach((value: number, key: number) => {
        sum += value;
        const element: JSX.Element = (
            <div key={key} onClick={onExpenseCategoryClick ? () => onExpenseCategoryClick(key) : null}>
                <span>{`${getNameByTypeId(key, expenseCategories)}:`}</span>
                <span>{convertToCurrency(value)}</span>
            </div>
        );
        elements.push(element);
    });

    return (
        <div className="expenses-summary">
            <div className="expenses-summary-title">{title}</div>
            <div className="expenses-summary-items">{elements}</div>
            <div className="expenses-summary-sum">Suma: {convertToCurrency(sum, true)}</div>
        </div>
    );
}
