import {Icon} from '@mp/common/svg';

export interface ItemsContainer {
    completed: Array<Item>;
    uncompleted: Array<Item>;
    itemsCount: number;
}

export interface Category {
    id: number;
    name: string;
    icon: Icon;
    items: ItemsContainer;
}

export interface Item {
    id: number;
    categoryId: number;
    title: string;
    description: string;
    completionDate: string;
}

export enum View {
    CATEGORIES_LIST = 'categories',
    NOT_COMPLETED_ITEMS_LIST = 'not-completed',
    COMPLETED_ITEMS_LIST = 'completed',
    ADD_ITEM = 'add',
    EDIT_ITEM = 'edit'
}
