import {resolveDisplayPoints} from './resolveDisplayPoints';
import {DartScoreHistory, Round, Score} from '../types';
import {DARTS_COUNT, POINTS_TO_WIN} from '../consts';

export interface PlayerRounds {
    rounds: Array<Round>;
    pointsLeft: number;
    isSelectedPlayer: boolean;
}

export function resolvePlayerRounds(playerHistory: DartScoreHistory, isSelectedPlayer: boolean): PlayerRounds {
    const rounds: Array<Round> = [];
    let sumPoints: number = 0;
    playerHistory.forEach((entry, index) => {
        if (index % DARTS_COUNT === 0) {
            const scoreValue1: number = getPoints();
            const scoreValue2: number = getPoints(1);
            const scoreValue3: number = getPoints(2);
            const points: number = scoreValue1 + scoreValue2 + scoreValue3;
            let isFailed: boolean = true;
            if (sumPoints + points <= POINTS_TO_WIN) {
                sumPoints += points;
                isFailed = false;
            }

            rounds.push({
                scoreValue1,
                scoreValue2,
                scoreValue3,
                scoreDisplay1: getDisplayPoints(),
                scoreDisplay2: getDisplayPoints(1),
                scoreDisplay3: getDisplayPoints(2),
                points,
                isFailed
            });
        }

        function getPoints(n: number = 0): number {
            const {value = 0, factor = 1} = playerHistory[index + n]?.score ?? {};
            return value * factor;
        }

        function getDisplayPoints(n: number = 0): string {
            const score: Partial<Score> = playerHistory[index + n]?.score ?? {};
            return resolveDisplayPoints(score);
        }
    });

    const pointsLeft: number = POINTS_TO_WIN - sumPoints;

    if (isSelectedPlayer && playerHistory.length % DARTS_COUNT === 0) {
        rounds.push({
            scoreValue1: 0,
            scoreValue2: 0,
            scoreValue3: 0,
            scoreDisplay1: '',
            scoreDisplay2: '',
            scoreDisplay3: '',
            points: 0,
            isFailed: false
        });
    }

    return {rounds, pointsLeft, isSelectedPlayer};
}
