export function clickOutside(event: Event, className: string): boolean {
    let element: HTMLElement = event?.target as HTMLElement;
    while (element) {
        if (element.classList.contains(className)) {
            return false;
        }
        element = element.parentElement;
    }
    return true;
}
