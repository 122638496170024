import {round} from '@mp/common/utils/number';
import {PaymentRow} from '../types';

interface Result {
    usedEnergy: number;
    usedColdWater: number;

    heatCost: number;
    energyCost: number;
    coldWaterCost: number;
    renovationFundCost: number;
    trashCost: number;

    totalCost: number;
}

export function calculateExpenses(lastRow: PaymentRow, secondToLastRow: PaymentRow): Result {
    if (lastRow == null || secondToLastRow == null) {
        return null;
    }

    if (
        lastRow.energy == null ||
        lastRow.energyCost == null ||
        lastRow.coldWater == null ||
        lastRow.coldWaterCost == null ||
        lastRow.renovationFundCost == null ||
        lastRow.trashCost == null
    ) {
        return null;
    }

    let usedEnergy = round(lastRow.energy - secondToLastRow.energy, 2);
    if (lastRow.energy < secondToLastRow.energy) {
        // new electric meter
        usedEnergy = round(lastRow.energy, 2);
    }
    const energyCost = round(usedEnergy * lastRow.energyCost, 2);
    const usedColdWater = !lastRow.coldWater ? 0 : lastRow.coldWater - secondToLastRow.coldWater;
    const coldWaterCost = round(usedColdWater * lastRow.coldWaterCost, 2);
    const heatCost: number = lastRow.heat;
    const renovationFundCost: number = lastRow.renovationFundCost;
    const trashCost: number = lastRow.trashCost;
    const totalCost: number = round(energyCost + heatCost + coldWaterCost + trashCost + renovationFundCost, 2);

    return {usedEnergy, usedColdWater, heatCost, energyCost, coldWaterCost, renovationFundCost, trashCost, totalCost};
}
