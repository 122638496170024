import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {toBoolean} from '@mp/common/utils/converters';
import {Icon} from '@mp/common/svg-icon';
import {ShoppingListItem} from '../types';

interface ShoppingListItemDTO {
    id: string;
    item_id: string;
    category_id: string;
    count: string;
    name: string;
    category: string;
    done: string;
    icon: string;
}

export interface LoadShoppingListServiceResult {
    listItems: Array<ShoppingListItem>;
    commonListItems: Array<ShoppingListItem>;
    otherListItems: Array<ShoppingListItem>;
}

export function loadShoppingListItemsService(): Promise<ServiceResponseWithError<LoadShoppingListServiceResult>> {
    return service
        .get<Array<ShoppingListItemDTO>>('shoppingList.loadListItems')
        .then((response: AxiosResponse<Array<ShoppingListItemDTO>>) => {
            const listItems: Array<ShoppingListItem> = resolveShoppingListItems(response.data);

            return {
                data: {
                    listItems,
                    commonListItems: listItems.filter((f: ShoppingListItem) => f.category !== 'inne'),
                    otherListItems: listItems.filter((f: ShoppingListItem) => f.category === 'inne')
                }
            };
        });

    function resolveShoppingListItems(rawItems: Array<ShoppingListItemDTO>): Array<ShoppingListItem> {
        return rawItems.map((item) => ({
            id: Number(item.id),
            item_id: Number(item.item_id),
            category_id: Number(item.category_id),
            count: Number(item.count),
            name: item.name,
            category: item.category,
            done: toBoolean(item.done),
            icon: item.icon as Icon
        }));
    }
}
