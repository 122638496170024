import {useLocation} from 'react-router';

export function useQueryParams(key: string): string {
    return useRawQueryValue(key);
}

export function useBooleanParams(key: string): boolean {
    return useRawQueryValue(key) === 'true';
}

export function useNumberQueryParams(key: string): number {
    const raw: string = useRawQueryValue(key);
    return raw ? Number(raw) : null;
}

function useRawQueryValue(key: string): string {
    const {search} = useLocation();
    return new URLSearchParams(search).get(key);
}
