import {DeleteServiceResponse, service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {MONTH_PARAM, YEAR_PARAM} from '@mp/route';
import {Expense, ExpenseCategory} from '../types';

const EXPENSES_SERVICE_NAME: string = 'expenses';

interface LoadExpensesServiceResponse {
    categories: Array<ExpenseCategory>;
    expenses: Array<Expense>;
}

export function loadExpensesService({year, month}: {year: number; month?: number}): Promise<LoadExpensesServiceResponse> {
    const monthString: string = month ? (month < 10 ? `0${month}` : `${month}`) : undefined;
    return service
        .get<LoadExpensesServiceResponse>(EXPENSES_SERVICE_NAME, {data: {[YEAR_PARAM]: year, [MONTH_PARAM]: monthString}})
        .then((response) => response.data);
}

export function addExpenseService(data: Expense): Promise<ServiceResponseWithId> {
    return service.post(EXPENSES_SERVICE_NAME, {data});
}

export function updateExpenseService(data: Expense): Promise<ServiceResponseWithAffectedRow> {
    return service.patch(EXPENSES_SERVICE_NAME, {data});
}

export function deleteExpenseService(id: number): Promise<DeleteServiceResponse> {
    return service.delete(EXPENSES_SERVICE_NAME, {data: {id}});
}
