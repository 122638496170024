import React, {ChangeEvent, CSSProperties, LegacyRef} from 'react';

interface InputParams {
    title?: string;
    name?: string;
    placeholder?: string;
    defaultValue?: string | number;
    maxLength?: number;
    autoFocus?: boolean;
    spellCheck?: boolean;
    inputMode?: 'numeric' | 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'decimal';
    pattern?: string;
    disabled?: boolean;
    autoCapitalize?: 'on' | 'none';
    isPassword?: boolean;
    style?: CSSProperties;
    reference?: LegacyRef<HTMLInputElement>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function TextInput(params: InputParams): JSX.Element {
    const {title} = params;
    return (
        <div className="mp-text-input" data-name={params.name}>
            {title && <div className="mp-text-input-title">{title}</div>}
            <input
                ref={params.reference}
                type={params.isPassword ? 'password' : 'text'}
                inputMode={params.inputMode}
                pattern={params.pattern}
                spellCheck={params.spellCheck}
                defaultValue={params.defaultValue}
                disabled={params.disabled}
                style={params.style}
                maxLength={params.maxLength}
                name={params.name}
                autoFocus={params.autoFocus}
                placeholder={params.placeholder}
                autoCapitalize={params.autoCapitalize ?? 'none'}
                onChange={params.onChange}
            />
        </div>
    );
}
