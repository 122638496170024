import {service, ServiceResponseWithAffectedRow} from '@mp/common/api';
import {ShoppingListItem, ItemCountModal} from '../types';

export function updateListItemIsDoneService(item: ShoppingListItem, done: boolean): Promise<ServiceResponseWithAffectedRow> {
    return service.patch('shoppingList.updateListItem', {data: {id: item.id, done}});
}

export function updateListItemCountService(item: Pick<ItemCountModal, 'id' | 'count'>): Promise<ServiceResponseWithAffectedRow> {
    const {id, count} = item;
    return service.patch('shoppingList.updateListItemCount', {data: {id, count}});
}
