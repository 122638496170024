import React from 'react';

const SKELETON: 'movie-info-skeleton' = 'movie-info-skeleton';

export function MovieInfoBoxSkeleton(): JSX.Element {
    return (
        <div className={SKELETON}>
            <div className={`${SKELETON}-top`}>
                <div className={`${SKELETON}-top-left`} />
                <div className={`${SKELETON}-top-right`}>
                    <div />
                    <div>
                        <i />
                        <i />
                        <i />
                    </div>
                    <div />
                </div>
            </div>
            <div className={`${SKELETON}-bottom`}>
                <div />
                <section>
                    <i />
                    <i />
                    <i />
                </section>
            </div>
        </div>
    );
}
