import classNames from 'classnames';
import React from 'react';
import {getMonthName} from '@mp/common/utils/date';
import {isNotEmpty} from '@mp/common/utils/array';
import {Icon, SvgIcon} from '@mp/common/svg';
import {resolveFullEventName} from '../utils/resolveFullEventName';
import {Grid, GridCell} from '../types';

const CDL: string = 'calendar-details-list';

export function DetailsList(props: {grid: Grid; displayedDate: Date; onDateClick: (gridCell: GridCell) => void}): JSX.Element {
    const {grid: _grid, displayedDate, onDateClick} = props;

    const rows: Array<JSX.Element> = [];
    const grid: Grid = removeCellsBeforeCurrentDay(_grid);
    grid.forEach((gridCell, key) => {
        rows.push(
            <div key={key} className={`${CDL}__item`}>
                {renderCalendarEventRow(gridCell, displayedDate, onDateClick)}
            </div>
        );
    });

    return <div className={CDL}>{rows}</div>;
}

function renderCalendarEventRow(gridCell: GridCell, displayedDate: Date, onDateClick: (gridCell: GridCell) => void): JSX.Element {
    const {dates: calendarDates} = gridCell;
    return (
        <div style={{display: 'flex', fontSize: '12px', cursor: 'pointer'}} onClick={() => onDateClick(gridCell)}>
            <div style={{marginRight: '5px', whiteSpace: 'nowrap'}}>
                {calendarDates[0].date.getDate()} {getMonthName(calendarDates[0].date)} -
            </div>
            <div>
                {calendarDates.map((cDate) => (
                    <div key={cDate.id + cDate.date.getTime()} className={classNames({'other-user-date': !cDate.isOwn})}>
                        <div>
                            <SvgIcon icon={cDate.isPublic ? Icon.EarthAmericas : Icon.Key} />
                            {resolveFullEventName(cDate, {showEventName: true, displayedDate})}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function removeCellsBeforeCurrentDay(grid): Grid {
    let result: Grid = [];
    grid.forEach((cell) => {
        if (cell.isCurrentDate) {
            result = [];
        }
        if (isNotEmpty(cell.dates)) {
            result.push(cell);
        }
    });
    return result;
}
