import {minDigits} from '@mp/common/utils/number';
import {Series} from '../types';

export function searchForSubtitles(series: Series): void {
    const {name, season, episode} = series;
    const link: string = `https://www.google.pl/search?q=${name} ${nextEpisode(season, episode)} napisy`;
    window.open(link);
}

function nextEpisode(season: number, episode: number): string {
    return `S${minDigits(season)}E${minDigits(Number(episode) + 1)}`;
}
