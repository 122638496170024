interface I18n {
    energy: string;
    hotWater: string;
    coldWater: string;
    heat: string;
    cost: string;
    date: string;
    noData: string;
}

export const t: I18n = {
    energy: 'Energia',
    hotWater: 'Ciepła woda',
    coldWater: 'Zimna woda',
    heat: 'Grzanie',
    cost: 'Koszt',
    date: 'Data',
    noData: 'Brak danych'
};
