import {InputData} from '@mp/common/components/form-input/types';
import {Invoice} from '../types';

export function getFromData(): Array<InputData<Invoice>> {
    return [
        {id: 'shopName', displayName: 'Nazwa sklepu', type: 'text', defaultValue: null, validation: {minLength: 3}},
        {id: 'details', displayName: 'Szczegóły', type: 'text', defaultValue: null, validation: {minLength: 3}},
        {id: 'value', displayName: 'Cena', type: 'text-number', defaultValue: null},
        {id: 'dateFrom', displayName: 'Kupiono', type: 'date', defaultValue: null},
        {id: 'dateTo', displayName: 'Gwarancja do', type: 'date', defaultValue: null}
    ];
}
