import {InputData} from '@mp/common/components/form-input/types';
import {TravelPayment, ViewMode} from '../types';

export function createFormInputs(
    selectedItem: TravelPayment,
    viewMode: ViewMode,
    isAdmin: boolean,
    selectedTravelId?: number
): Array<InputData<TravelPayment>> {
    const {id, name, description, valueK, valueM, notEditable, date} = selectedItem ?? {};

    const rows: Array<InputData<TravelPayment>> = [
        {id: 'id', displayName: '', type: 'hidden', defaultValue: id},
        {id: 'date', displayName: 'Data', type: 'date', defaultValue: date ?? (viewMode === ViewMode.Add ? new Date() : null)},
        {id: 'travelNameId', displayName: '', type: 'hidden', defaultValue: viewMode === ViewMode.Add && selectedTravelId},
        {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: name, validation: {minLength: 2, maxLength: 200}},
        {id: 'description', displayName: 'Opis', type: 'text', defaultValue: description, validation: {maxLength: 500}},
        {id: 'valueK', displayName: 'Klaudia', type: 'text-number', defaultValue: valueK},
        {id: 'valueM', displayName: 'Maciej', type: 'text-number', defaultValue: valueM}
    ];

    if (isAdmin) {
        rows.push({id: 'notEditable', displayName: 'Zablokuj', type: 'checkbox', defaultValue: notEditable ?? false});
    } else {
        rows.push({id: 'notEditable', displayName: 'Zablokuj', type: 'hidden', defaultValue: notEditable ? 'true' : 'false'}); // todo
    }

    return rows;
}
