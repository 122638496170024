import './_index.scss';
import {useParams} from 'react-router-dom';
import React from 'react';
import {useQueryParams} from '@mp/common/hooks/useQueryParams';
import {MovieTab, QUERY_PARAM, Router} from '@mp/route';
import {loadInCinemaMoviesService, loadToWatchMoviesService, loadWatchedMoviesService} from '../services';
import {TabItem, TabsNavigation} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {IWatchedMovies, WatchedMovies} from './watchedTab/WatchedMovies';
import {InCinemaView} from './inCinemaTab/InCinemaMoviesTab';
import {ToWatchMoviesView} from './toWatchTab/ToWatchMovies';
import {SearchMovies} from './searchTab/SearchMovie';
import {Loading} from '../../../components/loading';
import {Movie} from './inCinemaTab/types';
import {ToWatchMovie} from '../types';

const MP_MOVIES_PAGE: string = 'mp-movies-page';

interface MoviePageProps {
    selectedTab: MovieTab;
    query: string;
}

interface MoviesPageState {
    isLoaded: boolean;
    toWatchMovies: Array<ToWatchMovie>;
    watchedMovies: Array<IWatchedMovies>;
    inCinemaMovies: Array<Movie>;
    tabCounts: Map<MovieTab, number>;
}

export function MoviesPage(): JSX.Element {
    const {tab} = useParams<{tab}>();

    return <MoviesPageCC selectedTab={tab as MovieTab} query={useQueryParams(QUERY_PARAM)} />;
}

class MoviesPageCC extends React.Component<MoviePageProps, MoviesPageState> {
    constructor(props: MoviePageProps) {
        super(props);

        this.state = {
            tabCounts: new Map(),
            isLoaded: false,
            toWatchMovies: [],
            watchedMovies: [],
            inCinemaMovies: []
        };
    }

    public componentDidMount() {
        window.scrollTo({top: 0});
        Promise.all([loadToWatchMoviesService(), loadWatchedMoviesService(), loadInCinemaMoviesService()]).then(
            (responses: [Array<ToWatchMovie>, Array<IWatchedMovies>, Array<Movie>]) => {
                const toWatchMovies = responses[0];
                const watchedMovies = responses[1];
                const inCinemaMovies = responses[2];

                this.setState({
                    tabCounts: new Map([
                        ['in-cinema', inCinemaMovies.length],
                        ['to-watch', toWatchMovies.length],
                        ['watched', watchedMovies.length]
                    ]),
                    toWatchMovies,
                    watchedMovies,
                    inCinemaMovies,
                    isLoaded: true
                });
            }
        );
    }

    public render() {
        const {isLoaded} = this.state;
        const tabs: Array<TabItem> = [
            this.createTabItem('Szukaj', 'search'),
            this.createTabItem('Obejrzane', 'watched'),
            this.createTabItem('Do obejrzenia', 'to-watch'),
            this.createTabItem('W kinie', 'in-cinema')
        ];

        if (!isLoaded) {
            return <Loading />;
        }

        return (
            <div className={MP_MOVIES_PAGE}>
                <TabsNavigation tabs={tabs} />
                {this.renderView()}
            </div>
        );
    }

    private renderView(): JSX.Element {
        const {watchedMovies, toWatchMovies, inCinemaMovies} = this.state;
        const {selectedTab, query} = this.props;

        switch (selectedTab) {
            case 'search':
                return <SearchMovies query={query} />;
            case 'watched':
                return <WatchedMovies movies={watchedMovies} maxRows={100} />;
            case 'to-watch':
                return <ToWatchMoviesView movies={toWatchMovies} />;
            case 'in-cinema':
                return <InCinemaView movies={inCinemaMovies} />;
        }
        return null;
    }

    private createTabItem(name: string, tab: MovieTab): TabItem {
        const {tabCounts} = this.state;
        const {selectedTab} = this.props;
        const itemsCount: number = tabCounts.get(tab);
        let tabName: string = name;
        if (itemsCount != null) {
            tabName += ` (${itemsCount})`;
        }

        return {
            name: tabName,
            selected: selectedTab === tab,
            url: Router.getUrlToMoviePage(tab)
        };
    }
}
