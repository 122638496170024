import React from 'react';
import {Icon, SvgIcon} from '@mp/common/svg';

interface RatingStarsProps {
    rate: number;
    maxRate?: number;
}

export function RatingStars(props: RatingStarsProps): JSX.Element {
    const {rate, maxRate} = props;
    const stars: Array<JSX.Element> = [];

    for (let i = 1; i <= (maxRate ?? 3); i++) {
        if (i <= rate) {
            stars.push(<SvgIcon key={i} icon={Icon.StarFilled} />);
        } else {
            stars.push(<SvgIcon key={i} icon={Icon.Star} />);
        }
    }
    return (
        <span className="mp-rating-stars" style={{whiteSpace: 'nowrap'}}>
            {stars}
        </span>
    );
}
