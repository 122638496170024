import {toDateString} from '@mp/common/utils/converters';
import {currentDate} from '@mp/common/utils/date';
import {getAppSettings} from '../../global';

export const ID_PARAM: 'id' = 'id';
export const FILTER_PARAM: 'filter' = 'filter';
export const YEAR_PARAM: 'year' = 'year';
export const MONTH_PARAM: 'month' = 'month';
export const QUERY_PARAM: 'query' = 'query';
export const LIST_PARAM: 'list' = 'list';
export const DATE_PARAM: 'date' = 'date';
export const TIME_PARAM: 'time' = 'time';
export const IS_EDIT_PARAM: 'isEdit' = 'isEdit';
export const IS_EDIT_IMAGE_PARAM: 'isEditImage' = 'isEditImage';
export const EMPLOYER_ID_PARAM: 'employerId' = 'employerId';

export enum RouteName {
    APARTMENT_EXPENSES = '/apartment-expenses',
    BUCKET_LIST = '/bucket-list',
    BUSINESS_CARD = '/bussiness-cards',
    CALENDAR = '/calendar',
    CASH_ENVELOPES = '/cash-envelopes',
    CHECKLIST = '/checklist',
    CINEMA_PROGRAM = '/multikino',
    DARTS = '/darts',
    DEBTORS = '/debtors',
    DISCOUNT_CARDS = '/discount-cards',
    EPISODES = '/episodes',
    EXPENSES = '/expenses',
    FILES = '/files',
    FONTS = '/fonts',
    GAMES = '/games',
    HOME = '/',
    INVOICES = '/invoices',
    LINKS = '/link',
    LOGIN = '/login',
    MOVIES = '/movies',
    RECIPES = '/recipes',
    SETTINGS = '/settings',
    SHOPPING_LIST = '/shopping-list',
    SUBSCRIPTIONS = '/subscriptions',
    SUSHI = '/sushi',
    TRAVEL_PAYMENTS = '/travel-payments',
    WINES_RANKIN = '/wines-ranking',
    WISH_LIST = '/wish-list',
    WORK_HOURS = '/work-hours'
}

export const PUBLIC_ROUTES: Array<RouteName> = [RouteName.CINEMA_PROGRAM, RouteName.LOGIN, RouteName.DARTS];

export type MovieTab = 'search' | 'watched' | 'to-watch' | 'in-cinema';
export type EpisodesTab = 'active' | 'all' | 'add';

export enum WishListTab {
    TO_BUY = 'to-buy',
    BOUGHT = 'bought'
}

export enum EnglishTab {
    All = 'all',
    Test = 'test'
}
export enum ExpensesTab {
    MonthSummary = 'month-summary',
    YearSummary = 'year-summary',
    List = 'month-list'
}

export enum ShoppingListTab {
    COMMON = 'common',
    OTHER = 'other'
}

export class Router {
    public static getUrlToApartmentExpensesPage(params: {[YEAR_PARAM]: number; [ID_PARAM]?: number}): string {
        const {id, year} = params;
        return this.resolveUrl(RouteName.APARTMENT_EXPENSES, {[YEAR_PARAM]: year, [ID_PARAM]: id});
    }

    public static getUrlToMoviePage(tab?: MovieTab, query?: string): string {
        return this.resolveTabUrl(RouteName.MOVIES, tab ?? 'search', {[QUERY_PARAM]: query});
    }

    public static getUrlToBusinessCardsPage(tab: string): string {
        return this.resolveTabUrl(RouteName.BUSINESS_CARD, tab);
    }

    public static getUrlToCinemaProgramPage(params?: {date?: Date; id?: string}): string {
        const {id: tab, date} = params ?? {};
        return this.resolveTabUrl(RouteName.CINEMA_PROGRAM, tab, {[DATE_PARAM]: toDateString(date ?? currentDate(), 'yyyy-mm-dd')});
    }

    public static getUrlToEpisodesPage(tab: EpisodesTab): string {
        return this.resolveTabUrl(RouteName.EPISODES, tab);
    }

    public static getUrlToLinksPage(): string {
        return this.resolveUrl(RouteName.LINKS);
    }

    public static getUrlToWishListPage(tab: WishListTab): string {
        return this.resolveTabUrl(RouteName.WISH_LIST, tab);
    }

    public static getUrlToChecklistPage(params?: {[LIST_PARAM]?: number}): string {
        return this.resolveUrl(RouteName.CHECKLIST, params);
    }

    public static getUrlToCalendarPage(params: {[YEAR_PARAM]: number; [MONTH_PARAM]: number}): string {
        return this.resolveUrl(RouteName.CALENDAR, params);
    }

    public static getUrlToTravelPaymentPage(params?: {[ID_PARAM]?: number}): string {
        return this.resolveUrl(RouteName.TRAVEL_PAYMENTS, params);
    }

    public static getUrlToShoppingListPage(tab: ShoppingListTab): string {
        return this.resolveTabUrl(RouteName.SHOPPING_LIST, tab);
    }

    public static getUrlToHomePage(): string {
        return this.resolveUrl(RouteName.HOME);
    }

    public static getUrlToLoginPage(): string {
        return this.resolveUrl(RouteName.LOGIN);
    }

    public static getUrlToSettingsPage(): string {
        return this.resolveUrl(RouteName.SETTINGS);
    }

    public static getUrlToBucketListPage(params: {categoryId: number; view: string; editItemId: number}): string {
        return this.resolveUrl(RouteName.BUCKET_LIST, params);
    }

    public static getUrlToSushiPage(params?: {[FILTER_PARAM]?: number}): string {
        return this.resolveUrl(RouteName.SUSHI, params);
    }

    public static getUrlToRecipesPage(params?: {
        [ID_PARAM]?: number;
        [FILTER_PARAM]?: string;
        [IS_EDIT_PARAM]?: boolean;
        [IS_EDIT_IMAGE_PARAM]?: boolean;
    }): string {
        return this.resolveUrl(RouteName.RECIPES, params);
    }

    public static getUrlToWinesRankingPage(): string {
        return this.resolveUrl(RouteName.WINES_RANKIN);
    }

    public static getUrlToExpensesPage(tab: ExpensesTab, date?: Date): string {
        const newDate: Date = date ?? currentDate();
        return this.resolveTabUrl(RouteName.EXPENSES, tab, {[YEAR_PARAM]: newDate.getFullYear(), [MONTH_PARAM]: newDate.getMonth() + 1});
    }

    public static getUrlToWorkHoursPage(employerId?: number): string {
        return this.resolveUrl(RouteName.WORK_HOURS, {
            [EMPLOYER_ID_PARAM]: employerId ?? getAppSettings().workingHoursPageDefaultEmployerId
        });
    }

    public static getUrlToInvoicesPage(): string {
        return this.resolveUrl(RouteName.INVOICES);
    }

    public static getUrlToSubscriptionsPage(): string {
        return this.resolveUrl(RouteName.SUBSCRIPTIONS);
    }

    public static getUrlToDartsPage(): string {
        return this.resolveUrl(RouteName.DARTS);
    }

    public static getUrlToCashEnvelopesPage(id?: string): string {
        return this.resolveTabUrl(RouteName.CASH_ENVELOPES, id);
    }

    private static resolveTabUrl(routeName: RouteName, tab?: string, params?: object): string {
        const _routeName = tab ? `${routeName}/${tab}` : routeName;
        return this.resolveUrl(_routeName as RouteName, params);
    }

    private static resolveUrl(routeName: RouteName, params?: object): string {
        if (params == null) {
            return routeName;
        }
        const searchParams: URLSearchParams = new URLSearchParams();
        Object.entries(params).forEach((entry) => {
            if (entry[0] && entry[1] !== undefined) {
                searchParams.set(entry[0], entry[1]);
            }
        });

        const allParams: string = searchParams.toString();
        return allParams ? `${routeName}?${allParams}` : routeName;
    }
}
