import './_envelope.scss';
import classNames from 'classnames';
import React from 'react';
import {toCurrency} from '@mp/common/utils/number';
import {CashEnvelope} from '../types';

export function Envelope({cashEnvelope}: {cashEnvelope: CashEnvelope}): JSX.Element {
    const {value, name} = cashEnvelope;

    return (
        <div className="envelope-wrapper">
            <div className={classNames('envelope', {open: true, close: false})}>
                <div className="front flap"></div>
                <div className="front pocket"></div>
                <div className="letter">
                    {name && <div>{name}</div>}
                    <div className="letter-value">{toCurrency(value)}</div>
                </div>
            </div>
        </div>
    );
}
