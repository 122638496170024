import React from 'react';

export interface AccordionProps {
    label: string;
    content?: string;
    isOpened?: boolean;
    elContent?: JSX.Element | Array<JSX.Element>;
}

export interface AccordionsProps {
    accordionRows: Array<AccordionProps>;
}

export class Accordion extends React.Component<AccordionsProps, {}> {
    constructor(props: AccordionsProps) {
        super(props);

        this.onLabelClick = this.onLabelClick.bind(this);

        this.state = {};
    }

    render() {
        return <mp-accordion>{this.renderAccordionRows(this.props.accordionRows)}</mp-accordion>;
    }

    private renderAccordionRows(accordionRows: Array<AccordionProps>): JSX.Element {
        return (
            <>
                {accordionRows.map((accordion: AccordionProps) => {
                    return (
                        <div key={accordion.label} className={`accordion-item ${accordion.isOpened && 'active'}`}>
                            <div
                                className="label"
                                dangerouslySetInnerHTML={{__html: accordion.label}}
                                onClick={(event) => this.onLabelClick(event)}
                            />
                            {accordion.content ? (
                                <div className="content" dangerouslySetInnerHTML={{__html: accordion.content}} />
                            ) : (
                                <div className="content">{accordion.elContent}</div>
                            )}
                        </div>
                    );
                })}
            </>
        );
    }

    private onLabelClick(event: React.MouseEvent) {
        if (event?.currentTarget?.parentElement) {
            event.currentTarget.parentElement.classList.toggle('active');
        }
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'mp-accordion': React.HTMLAttributes<HTMLElement>;
        }
    }
}
