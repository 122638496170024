import './_loading.scss';
import React from 'react';

const MP_LOADING: 'mp-loading' = 'mp-loading';

export function Loading(): JSX.Element {
    return <MP_LOADING />;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [MP_LOADING]: React.HTMLAttributes<HTMLElement>;
        }
    }
}
