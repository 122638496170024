import React, {CSSProperties, LegacyRef} from 'react';

interface TextAreaParams {
    title?: string;
    name?: string;
    placeholder?: string;
    defaultValue?: string | number;
    maxLength?: number;
    autoFocus?: boolean;
    spellCheck?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    reference?: LegacyRef<HTMLTextAreaElement>;
}

export function TextArea(params: TextAreaParams): JSX.Element {
    const {title} = params;
    return (
        <div className="mp-text-area">
            {title && <div className="mp-text-area-title">{title}</div>}
            <textarea
                ref={params.reference}
                spellCheck={params.spellCheck}
                defaultValue={params.defaultValue}
                disabled={params.disabled}
                style={params.style}
                maxLength={params.maxLength}
                name={params.name}
                autoFocus={params.autoFocus}
                placeholder={params.placeholder}
            />
        </div>
    );
}
