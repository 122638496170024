export interface GridDate {
    date: Date;
    monthType: 'previous' | 'current' | 'next';
    isCurrentDate?: boolean;
}

export type Grid = Array<GridCell>;

export interface GridCell extends GridDate {
    dates: Array<CalendarDate>;
}

export enum ViewMode {
    Calendar,
    PreviewDates,
    AddDate,
    EditDate
}

export interface CalendarDate {
    id: number;
    isPublic: boolean;
    showYears: boolean;
    name: string;
    type: CalendarEventType;
    originDate: Date;
    date: Date;
    dateTo: Date;
    timeFrom: string;
    timeTo: string;
    doneDate: Date;
    isRemovable: boolean;
    repeat: CalendarRepeatType;
    isOwn: boolean;
    everyWeek: boolean;
}

export enum CalendarEventType {
    BIRTHDAY = 'birthday',
    NAMEDAY = 'nameday',
    EVENT = 'event'
}

// immutable
export enum CalendarRepeatType {
    NONE = 'none',
    MONTH_EXACT_DAY = 'month-exact-day',
    YEAR = 'year'
}
