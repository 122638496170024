interface I18n {
    name: string;
    price: string;
    originCountry: string;
    color: string;
    vineStock: string;
    dryness: string;
    type: string;
    strength: string;
    rankingValue: string;
    imageLink: string;
}

export const t: I18n = {
    name: 'Nazwa',
    price: 'Cena',
    originCountry: 'Kraj pochodzenia',
    color: 'Kolor',
    vineStock: 'Szczep',
    dryness: 'Wytrawność',
    type: 'Rodzaj',
    strength: 'Moc',
    rankingValue: 'Ocena',
    imageLink: 'Link do obrazka'
};
