import {AxiosResponse} from 'axios';
import {isEmpty} from '@mp/common/utils/array';
import {service} from '@mp/common/api';
import {TravelPayment, TravelPaymentsMap} from '../types';
import {TRAVEL_PAYMENT_SERVICE} from './const';

interface TravelPaymentsDTO {
    payments: Array<TravelPaymentDTO>;
}
interface TravelPaymentDTO {
    id: number;
    date: string;
    travelNameId: number;
    name: string;
    description: string;
    valueSum: number;
    valueK: number;
    valueM: number;
    notEditable: boolean;
}

export function loadTravelDetailsService(travelNameId: number): Promise<TravelPaymentsMap> {
    return service
        .get<TravelPaymentsDTO>(TRAVEL_PAYMENT_SERVICE, {data: {travelNameId}})
        .then((result: AxiosResponse<TravelPaymentsDTO>) => resolveTravelPayment(result.data.payments));
}

function resolveTravelPayment(rawPayments: Array<TravelPaymentDTO>): TravelPaymentsMap {
    if (isEmpty(rawPayments)) {
        return new Map<string, Array<TravelPayment>>();
    }

    const payments: Array<TravelPayment> = rawPayments
        .map((raw) => ({
            id: raw.id,
            travelNameId: raw.travelNameId,
            date: raw.date ? new Date(raw.date) : null,
            name: raw.name,
            description: raw.description,
            valueSum: raw.valueK + raw.valueM,
            valueK: raw.valueK,
            valueM: raw.valueM,
            notEditable: raw.notEditable
        }))
        .sort((a: TravelPayment, b: TravelPayment) => {
            if (a.date && b.date) {
                return a.date.getTime() - b.date.getTime();
            }
            return a.id - b.id;
        });

    const travelPaymentMap: TravelPaymentsMap = new Map<string, Array<TravelPayment>>();
    payments.forEach((payment) => {
        const upperCaseName: string = payment.name.toUpperCase();
        const _payments: Array<TravelPayment> = travelPaymentMap.get(upperCaseName) ?? [];
        _payments.push(payment);
        travelPaymentMap.set(upperCaseName, _payments);
    });

    return travelPaymentMap;
}
