import React from 'react';
import {Link} from 'react-router-dom';
import {SvgIcon} from '@mp/common/svg';
import {TileItemParams} from '../../../../components/header/tileItems';

export function TileItem(params: TileItemParams): JSX.Element {
    const {title, svgIcon, route} = params;

    if (route.startsWith('http://') || route.startsWith('https://')) {
        return (
            <a className="home-tile-item" href={route} key={svgIcon}>
                <SvgIcon icon={svgIcon} />
                <div>{title}</div>
            </a>
        );
    }
    return (
        <Link className="home-tile-item" to={route} key={svgIcon}>
            <SvgIcon icon={svgIcon} />
            <div>{title}</div>
        </Link>
    );
}
