import {DateDay, removeTime} from '@mp/common/utils/date';

export function getWorkingDaysCount(firstDayOfBillingCycle: Date, lastDayOfBillingCycle: Date): number {
    const fromDate: Date = removeTime(firstDayOfBillingCycle);
    const toDate: Date = removeTime(lastDayOfBillingCycle);
    let count = 0;
    const curDate = new Date(fromDate.getTime());
    while (curDate <= toDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== DateDay.Saturday && dayOfWeek !== DateDay.Sunday) count++;
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
}
