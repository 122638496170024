import {service, ServiceResponseWithId} from '@mp/common/api';
import {SearchMovieResult} from '@mp/filmweb/types';
import {MOVIES_SERVICE_NAME} from '../const';

interface AddMovieServiceProps {
    film: SearchMovieResult;
    titlePl: string;
    titleOrg: string;
    cinema: boolean;
    watched: boolean;
}

export function addMovieService(props: AddMovieServiceProps): Promise<ServiceResponseWithId> {
    const {titleOrg, titlePl, cinema, watched, film} = props;
    return service.post(MOVIES_SERVICE_NAME, {
        data: {tpl: titlePl, ten: titleOrg, year: film.info.year, poster: film.info.posterPath, cinema, watched, fid: film.fid}
    });
}
