const INT_TO_DAY_MAP: Map<number, string> = new Map<number, string>([
    [1, 'Pn'],
    [2, 'Wt'],
    [3, 'Śr'],
    [4, 'Cz'],
    [5, 'Pt'],
    [6, 'Sb'],
    [7, 'Nd']
]);

export function intToDay(int: number) {
    return INT_TO_DAY_MAP.get(int) ?? 'error';
}
