import {Settings, UserType} from '@mp/common/types';

export const HOST: string = 'www.polakos.pl';
export const ORIGIN: string = `https://${HOST}`;

let _userType: UserType = null;
export function setUserType(userType: UserType): void {
    _userType = userType;
}

export function isAdmin(): boolean {
    return _userType === UserType.ADMIN;
}

export function isLoggedUser(): boolean {
    return _userType === UserType.LOGGED_USER;
}

export function isAdminOrLoggedUser(): boolean {
    return isAdmin() || isLoggedUser();
}

let _appSettings: Settings;
export function setAppSettings(settings: Settings): void {
    _appSettings = settings;
}

export function getAppSettings(): Settings {
    return _appSettings;
}
