import {Ingredient} from '../../types';
import {DEFAULT_INGREDIENT_SECTION} from '../../consts';

export function sortIngredients(ingredients: Array<Ingredient>): Array<Ingredient> {
    ingredients.sort((a: Ingredient, b: Ingredient) => {
        if (a.section === DEFAULT_INGREDIENT_SECTION) {
            return -1;
        }
        if (b.section === DEFAULT_INGREDIENT_SECTION) {
            return 1;
        }
        if (a.section === b.section) {
            return a.name.localeCompare(b.name);
        }

        return a.section.localeCompare(b.section);
    });

    return ingredients;
}
