import './_index.scss';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {useNumberQueryParams, useQueryParams} from '@mp/common/hooks/useQueryParams';
import {Router} from '@mp/route';
import {addItemService, loadCategoriesService, updateItemService} from '../services';
import {CategoriesListView} from './CategoriesListView';
import {Loading} from '../../../components/loading';
import {AddEditItemView} from './AddEditItemView';
import {getAppSettings} from '../../../global';
import {Category, Item, View} from '../types';
import {ItemListView} from './ItemListView';

const MP_BUCKET_LIST: 'mp-bucket-list-page' = 'mp-bucket-list-page';

interface BucketListProps {
    navigate: NavigateFunction;
    selectedCategoryId: number;
    selectedView: string;
    editItemId: number;
}

interface BucketListState {
    isLoaded: boolean;
    categories: Array<Category>;
    items: Array<Item>;
}

export function BucketListPage(): JSX.Element {
    const view: string = useQueryParams('view');
    const categoryId: number = useNumberQueryParams('categoryId');
    const editItemId: number = useNumberQueryParams('editItemId');

    return <BucketListPageCC navigate={useNavigate()} editItemId={editItemId} selectedCategoryId={categoryId} selectedView={view} />;
}

class BucketListPageCC extends React.Component<BucketListProps, BucketListState> {
    private readonly detailsVisible: boolean;

    constructor(props: BucketListProps) {
        super(props);

        this.state = {isLoaded: false, categories: [], items: []};

        this.addItemToCategory = this.addItemToCategory.bind(this);
        this.updateItem = this.updateItem.bind(this);

        this.detailsVisible = getAppSettings().bucketListPageVisibleDetails;
    }

    public componentDidMount() {
        this.loadCategories();
    }

    public componentDidUpdate(prevProps: Readonly<BucketListProps>): void {
        if (this.props.selectedView !== prevProps.selectedView && this.props.selectedCategoryId) {
            const category: Category = this.state.categories.find((f) => Number(f.id) === this.props.selectedCategoryId);
            this.filterItemsByView(category);
        }
    }

    public render() {
        const {isLoaded} = this.state;
        if (!isLoaded) {
            return <Loading />;
        }

        return <div className={MP_BUCKET_LIST}>{this.renderView()}</div>;
    }

    private renderView(): JSX.Element {
        const {selectedCategoryId} = this.props;
        const {categories, items} = this.state;

        const view: View = this.resolveView();
        switch (view) {
            case View.CATEGORIES_LIST:
                return <CategoriesListView categories={categories} />;
            case View.NOT_COMPLETED_ITEMS_LIST:
            case View.COMPLETED_ITEMS_LIST:
                const currentCategory = categories.find((f) => Number(f.id) === selectedCategoryId);
                const isCompleted: boolean = view === View.COMPLETED_ITEMS_LIST;
                return (
                    <ItemListView items={items} category={currentCategory} isCompleted={isCompleted} detailsVisible={this.detailsVisible} />
                );
            case View.ADD_ITEM:
            case View.EDIT_ITEM:
                const isEdit: boolean = view === View.EDIT_ITEM;
                const currentItem: Item = this.state.items.find((f) => Number(f.id) === this.props.editItemId);
                if (isEdit && !currentItem) {
                    return null;
                }
                return (
                    <AddEditItemView
                        currentItem={currentItem}
                        categories={categories}
                        isEdit={isEdit}
                        categoryId={selectedCategoryId}
                        onAddItem={({title, details}) => this.addItemToCategory(title, details)}
                        onUpdateItem={(item: Item) => this.updateItem(item)}
                    />
                );
            default:
                return <div>Error</div>;
        }
    }

    private loadCategories(redirectLocation?: string) {
        loadCategoriesService().then((result) =>
            this.setState({categories: result.data, isLoaded: true}, () => {
                const categoryId: number = this.props.selectedCategoryId;
                if (categoryId) {
                    const category: Category = this.state.categories.find((f) => Number(f.id) === this.props.selectedCategoryId);
                    this.filterItemsByView(category);
                }
                if (redirectLocation) {
                    this.props.navigate(redirectLocation);
                }
            })
        );
    }

    private addItemToCategory(title: string, description: string): void {
        const {selectedCategoryId} = this.props;
        this.setState({isLoaded: false});
        addItemService({categoryId: selectedCategoryId, title, description}).then(({success}) => {
            if (success) {
                this.loadCategories(
                    Router.getUrlToBucketListPage({categoryId: selectedCategoryId, view: View.NOT_COMPLETED_ITEMS_LIST, editItemId: null})
                );
            }
        });
    }

    private filterItemsByView(category: Category) {
        const completedTasks: boolean = this.props.selectedView === View.COMPLETED_ITEMS_LIST;
        const items: Array<Item> = completedTasks ? category.items.completed : category.items.uncompleted;
        this.setState({items});
    }

    private updateItem(item: Item): void {
        this.setState({isLoaded: false});
        updateItemService(item).then(({success}) => {
            if (success) {
                this.loadCategories(
                    Router.getUrlToBucketListPage({categoryId: item.categoryId, view: View.NOT_COMPLETED_ITEMS_LIST, editItemId: null})
                );
            }
        });
    }

    private resolveView(): View {
        const {selectedCategoryId, selectedView} = this.props;

        if (!selectedCategoryId) {
            return View.CATEGORIES_LIST;
        }

        return selectedView as View;
    }
}
