import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {isEmpty, lastElement} from '@mp/common/utils/array';
import {toDateTime} from '@mp/common/utils/converters';
import {addSeconds} from '@mp/common/utils/date';
import {DateSection} from '../types';

interface DateDTO {
    time_in: string;
}

export function loadDateSectionsService(employerId: number): Promise<ServiceResponseWithError<Array<DateSection>>> {
    return service.get<Array<DateDTO>>('workHours.loadLastDates', {data: {employerId}}).then((response: AxiosResponse<Array<DateDTO>>) => ({
        data: resolveDateSections(response.data)
    }));
}

function resolveDateSections(rawEndDates: Array<DateDTO>): Array<DateSection> {
    if (isEmpty(rawEndDates)) {
        return [];
    }
    const dateSections: Array<DateSection> = rawEndDates.map((rawEndDate, index) => {
        const endDate: Date = toDateTime(rawEndDate.time_in);
        let startDate: Date = null;
        if (index === 0) {
            startDate = new Date(2000, 0, 1);
        } else {
            startDate = toDateTime(rawEndDates[index - 1].time_in);
            startDate = addSeconds(startDate);
        }
        return {
            start: startDate,
            end: endDate
        };
    });

    dateSections.push({
        start: addSeconds(lastElement(dateSections).end),
        end: new Date(9999, 0, 1)
    });

    return dateSections;
}
