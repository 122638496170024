import './_movieDetailsComponent.scss';
import React from 'react';
import {loadFilmInfoService} from '@mp/filmweb/services/loadFilmInfo.service';
import {getFullImagePath} from '@mp/filmweb/utils/imagePath';
import {changeImageSize} from '@mp/filmweb/utils/filmImage';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import {FilmInfo} from '@mp/filmweb/types';
import {Loading} from '../../../../components/loading';

const MP_MOVIE_DETAILS: 'mp-movie-details' = 'mp-movie-details';

interface MovieDetailsProps {
    fid: number;
    title: string;
}

interface MovieDetailsState {
    movie: FilmInfo;
    isLoaded: boolean;
}

export class MovieDetailsComponent extends React.Component<MovieDetailsProps, MovieDetailsState> {
    constructor(props: MovieDetailsProps) {
        super(props);

        this.state = {
            movie: null,
            isLoaded: false
        };
    }

    public componentDidMount() {
        loadFilmInfoService(this.props.fid).then((filmInfo) => this.setState({movie: filmInfo, isLoaded: true}));
    }

    public render() {
        if (!this.state.isLoaded) {
            return <Loading />;
        } else if (this.state.movie) {
            const movie: FilmInfo = this.state.movie;
            return (
                <MP_MOVIE_DETAILS>
                    <div className="title-1">
                        <a href={createLinkToMovie(movie.id)}>
                            {movie.title} ({movie.year})
                        </a>
                    </div>
                    <div className="img-container">
                        <a href={createLinkToMovie(movie.id)}>
                            <img src={getFullImagePath(changeImageSize(movie.posterPath, 3))} alt="poster" />{' '}
                        </a>
                    </div>
                </MP_MOVIE_DETAILS>
            );
        }
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [MP_MOVIE_DETAILS]: React.HTMLAttributes<HTMLElement>;
        }
    }
}
