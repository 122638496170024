import {InputData} from '@mp/common/components/form-input/types';
import {Debt} from '../../types';

interface GetInputsDataProps {
    selectedItem?: Debt;
    debtorName?: string;
}

export function getInputsData(props: GetInputsDataProps): Array<InputData<Debt>> {
    const {selectedItem, debtorName} = props;
    const {id, name, value, description} = selectedItem ?? {};

    return [
        {displayName: '', id: 'id', type: 'hidden', defaultValue: id ?? ''},
        {
            displayName: 'Dłużnik',
            id: 'name',
            type: 'text',
            defaultValue: name ?? debtorName,
            validation: {minLength: 3, maxLength: 20}
        },
        {
            displayName: 'Dług (zł)',
            id: 'value',
            type: 'text-number',
            defaultValue: value,
            validation: {digitsOnly: true}
        },
        {
            displayName: 'Opis',
            id: 'description',
            type: 'text',
            defaultValue: description,
            validation: {minLength: 3, maxLength: 200}
        }
    ];
}
