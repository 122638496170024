import {Router, ShoppingListTab} from '@mp/route';
import {TabItem} from '../../../../components/tabs-navigation/ui/TabsNavigation';

interface GetNavigationTabsParams {
    selectedTab: ShoppingListTab;
    commonListItemsCount: number;
    otherListItemsCount: number;
}

export function getNavigationTabs(params: GetNavigationTabsParams): Array<TabItem> {
    const {selectedTab, commonListItemsCount, otherListItemsCount} = params;
    return [
        {
            name: `Codzienne (${commonListItemsCount})`,
            url: Router.getUrlToShoppingListPage(ShoppingListTab.COMMON),
            selected: selectedTab === ShoppingListTab.COMMON
        },
        {
            name: `Inne (${otherListItemsCount})`,
            url: Router.getUrlToShoppingListPage(ShoppingListTab.OTHER),
            selected: selectedTab === ShoppingListTab.OTHER
        }
    ];
}
