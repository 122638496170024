import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// import {loadCriticsRatingService} from '@mp/filmweb/services/loadCriticsRating.service';
import {loadFilmRatingService} from '@mp/filmweb/services/loadFilmRating.service';
import {searchMoviesService} from '@mp/filmweb/services/searchMovies.service';
import {TITLE_TO_FILM_WEB_DATA_MAP} from '@mp/filmweb/consts';
import {FW_FAVICON} from '@mp/filmweb/assets/fw-favicon';
import {normalizeString} from '@mp/common/utils/string';
import {Icon, SvgButton} from '@mp/common/svg';
import {FilmRating} from '@mp/filmweb/types';
import {Router} from '@mp/route';
import {getShareLink} from '../utils/getMessengerShareLink';
import {isAdminOrLoggedUser} from '../../../global';
import {MovieInfo, Schedule} from '../types';
import {ScheduleTile} from './ScheduleTile';
import {RatingBox} from './RatingBox';

export function MovieInfoBox(props: {movieInfo: MovieInfo; date: Date; onScheduleClick: (schedule: Schedule) => void}): JSX.Element {
    const {movieInfo, date, onScheduleClick} = props;
    const [isLoadingFilmWebData, setIsLoadingFilmWebData] = useState<boolean>(false);
    const [filmRating, setFilmRating] = useState(null);
    const {title, filmParams, poster, filmUrl, schedules, promoLabels, dayTitle} = movieInfo;
    const movieId: string = normalizeString(title);

    useEffect(
        () => {
            const cashedPageData: FilmRating = TITLE_TO_FILM_WEB_DATA_MAP.get(title);
            if (cashedPageData) {
                setFilmRating(cashedPageData);
            }
        },
        // eslint-disable-next-line
        []
    );

    const isLogged: boolean = isAdminOrLoggedUser();

    return (
        <div id={movieId} className="movie-info">
            <div className="movie-info-top">
                <div className="movie-info-top-left">
                    {resolvePromoLabels()}
                    <img src={poster} alt={poster} onClick={() => onScheduleClick(null)} />
                </div>
                <div className="movie-info-top-right">
                    <div className="movie-info-top-right-title">
                        <div>
                            <a href={filmUrl}>{title}</a>
                        </div>
                        <div className="movie-info-top-right-title-buttons">
                            {isLogged && (
                                <Link to={Router.getUrlToMoviePage('search', title)}>
                                    <SvgButton icon={Icon.Video} />
                                </Link>
                            )}
                            <a href={getShareLink({date: date, id: movieId})}>
                                <SvgButton icon={Icon.Share} />
                            </a>
                            {isLogged && !filmRating && <img onClick={() => onLoadFilmWebDataClick()} src={FW_FAVICON} alt={'fav'} />}
                        </div>
                    </div>
                    {isLoadingFilmWebData ? <span>Wczytywanie</span> : <RatingBox filmRating={filmRating} />}
                    <div className="movie-info-top-right-params" dangerouslySetInnerHTML={{__html: filmParams}} />
                </div>
            </div>

            <div className="movie-info-bottom">
                <div className="movie-info-bottom-day">{dayTitle}</div>
                <div className="movie-info-bottom-grid">
                    {schedules?.map((schedule: Schedule) => (
                        <ScheduleTile key={schedule.bookingLink} movieId={movieId} schedule={schedule} onClick={onScheduleClick} />
                    ))}
                </div>
            </div>
        </div>
    );

    function resolvePromoLabels(): JSX.Element {
        return (
            <div className="promo-label-container">
                {promoLabels?.map((label: string) => (
                    <span key={label} className={label.toLowerCase()}>
                        {label}
                    </span>
                ))}
            </div>
        );
    }

    function onLoadFilmWebDataClick(): void {
        const cashedPageData: FilmRating = TITLE_TO_FILM_WEB_DATA_MAP.get(title);
        if (cashedPageData) {
            setFilmRating(cashedPageData);
        } else {
            setIsLoadingFilmWebData(true);
            searchMoviesService(title, 0).then((result) => {
                // loadCriticsRatingService(result[0].fid).then();
                loadFilmRatingService(result[0].fid).then((_filmRating) => {
                    if (_filmRating) {
                        TITLE_TO_FILM_WEB_DATA_MAP.set(title, _filmRating);
                        setFilmRating(_filmRating);
                        setIsLoadingFilmWebData(false);
                    }
                });
            });
        }
    }
}
