import {Modal} from './Modal';
import React, {PropsWithChildren} from 'react';
import {Button} from '../components/button/ui/Button';

interface ConfirmModalProps extends PropsWithChildren {
    title: string;
    question: string;
    handleClose: () => void;
    handleConfirm: () => void;
}

export function ConfirmModal({title, question, handleClose, handleConfirm}: ConfirmModalProps): JSX.Element {
    return (
        <Modal
            size="small"
            handleClose={handleClose}
            title={title || ' '}
            footer={
                <>
                    <Button title="Tak" onClick={handleConfirm} />
                    <Button title="Nie" onClick={handleClose} />
                </>
            }
        >
            {question}
        </Modal>
    );
}
