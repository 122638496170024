import classNames from 'classnames';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
import {SelectSingleIcon} from '@mp/common/components/select-icon/ui/SelectSingleCategory';
import {SelectSingleCategoryItem} from '@mp/common/components/select-icon/types';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {TextArea} from '@mp/common/components/text-area/ui/TextArea';
import {CheckBox} from '@mp/common/components/check-box/ui/CheckBox';
import {getCurrentDate} from '@mp/common/utils/date';
import {Icon, SvgButton} from '@mp/common/svg';
import {Router} from '@mp/route';
import {getLightColor} from '../utils/colors';
import {Category, Item, View} from '../types';

const HIDDEN_CLASS: string = 'hidden';

interface AddEditItemViewProps {
    isEdit: boolean;
    currentItem: Item;
    categories: Array<Category>;
    categoryId: number;
    onAddItem: (props: {title: string; details: string}) => void;
    onUpdateItem: (item: Item) => void;
}

export function AddEditItemView(props: AddEditItemViewProps): JSX.Element {
    const {isEdit, currentItem, categories, onAddItem, onUpdateItem} = props;
    const [selectedCategoryId, setSelectedCategoryId] = useState(props.categoryId);

    const titleInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    const detailsInput: React.RefObject<HTMLTextAreaElement> = React.createRef<HTMLTextAreaElement>();
    const completedInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    const dateInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    return (
        <div className={isEdit ? 'edit-item' : 'add-item'}>
            <div id="bucketlist-view-color" style={{backgroundColor: getLightColor(selectedCategoryId)}}>
                <div className={`category-title`}>
                    <Link
                        to={Router.getUrlToBucketListPage({
                            categoryId: selectedCategoryId,
                            view: View.NOT_COMPLETED_ITEMS_LIST,
                            editItemId: null
                        })}
                    >
                        <SvgButton icon={Icon.ArrowLeft} color="black" />
                    </Link>
                    <span>{isEdit ? 'Edycja' : 'Nowy'}</span>
                    {isEdit ? (
                        <SvgButton
                            icon={Icon.CloudArrowDown}
                            color="black"
                            onClick={() => {
                                onUpdateItem({
                                    id: currentItem.id,
                                    categoryId: selectedCategoryId,
                                    title: titleInput.current.value,
                                    description: detailsInput.current.value,
                                    completionDate: completedInput.current.checked ? dateInput.current.value : null
                                });
                            }}
                        />
                    ) : (
                        <SvgButton
                            icon={Icon.CloudArrowDown}
                            color="black"
                            onClick={() => onAddItem({title: titleInput.current.value, details: detailsInput.current.value})}
                        />
                    )}
                </div>
                <div className="edit-new">
                    <TextInput title="Tytuł" maxLength={20} reference={titleInput} defaultValue={isEdit ? currentItem.title : ''} />
                    <TextArea
                        title="Szczegóły"
                        maxLength={200}
                        reference={detailsInput}
                        defaultValue={isEdit ? currentItem.description : ''}
                    />
                    <SelectSingleIcon
                        title="Kategoria"
                        showLabel={true}
                        onItemSelected={(id) => setSelectedCategoryId(id)}
                        defaultCategoryId={selectedCategoryId}
                        items={categories.map((c: Category): SelectSingleCategoryItem => ({id: c.id, icon: c.icon as Icon, label: c.name}))}
                        disabled={!isEdit}
                    />
                    <CheckBox
                        title="Zakończone"
                        reference={completedInput}
                        disabled={!isEdit}
                        onChange={(event) => onCompletedCbChanged(event)}
                    />
                    <DateInput
                        title="Data zakończenia"
                        className={classNames(HIDDEN_CLASS, 'end-date-input')}
                        defaultValue={getCurrentDate()}
                        reference={dateInput}
                    />
                    <div className="footer" style={{backgroundColor: getLightColor(selectedCategoryId)}} />
                </div>
            </div>
        </div>
    );
}

function onCompletedCbChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const dateElement: HTMLInputElement = document.querySelector('.end-date-input');
    const target = event.currentTarget;
    if (target.checked) {
        dateElement.classList.remove(HIDDEN_CLASS);
    } else {
        dateElement.classList.add(HIDDEN_CLASS);
    }
}
