import {service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {Restaurant, Sushi} from '../types';

const SUSHI_SERVICE_NAME: string = 'sushi';

interface LoadSushiWithRestaurantsServiceResult {
    sushi: Array<Sushi>;
    restaurants: Array<Restaurant>;
}
export function loadSushiWithRestaurantsService(): Promise<LoadSushiWithRestaurantsServiceResult> {
    return service.get<LoadSushiWithRestaurantsServiceResult>(SUSHI_SERVICE_NAME).then(({data}) => data);
}

export function addSushiService(data: Sushi): Promise<ServiceResponseWithId> {
    return service.post(SUSHI_SERVICE_NAME, {data});
}

export function editSushiService(data: Sushi): Promise<ServiceResponseWithAffectedRow> {
    return service.put(SUSHI_SERVICE_NAME, {data});
}
