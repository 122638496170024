import {InputData, SelectOption} from '@mp/common/components/form-input/types';
import {Restaurant, Sushi, SushiRate} from '../types';
import {isAdmin} from '../../../global';

export function getEditInputsData(restaurants: Array<Restaurant>, selectedSushi?: Sushi): Array<InputData<Sushi>> {
    const {id: sushiId, name: sushiName, ingredients, restaurantId, rateK, rateM} = selectedSushi ?? {};
    const isUserAdmin: boolean = isAdmin();

    const ADD_INPUTS: Array<InputData<Sushi>> = [
        {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: sushiName ?? '', validation: {minLength: 3, maxLength: 100}},
        {id: 'ingredients', displayName: 'Składniki', type: 'text', defaultValue: ingredients ?? ''},
        {
            id: 'restaurantId',
            displayName: 'Restauracja',
            type: 'select-number',
            selectOptions: resolveRestaurantOptions(),
            defaultValue: restaurantId ?? null
        },
        {
            id: 'rateK',
            displayName: 'Ocena Klaudia',
            type: 'select',
            selectOptions: resolveRateOptions(),
            defaultValue: getRateValue(rateK)
        },
        {
            id: 'rateM',
            displayName: 'Ocena Maciej',
            type: 'select',
            selectOptions: resolveRateOptions(),
            defaultValue: getRateValue(rateM),
            disabled: !isUserAdmin
        }
    ];

    if (selectedSushi == null) {
        return ADD_INPUTS;
    }

    return [{id: 'id', displayName: 'Id', type: 'hidden', defaultValue: sushiId}, ...ADD_INPUTS];

    function resolveRestaurantOptions(): Array<SelectOption> {
        const options: Array<SelectOption> = restaurants.map(({id, name}) => ({name, value: id.toString()}));
        options.unshift({name: '', value: null});
        return options;
    }
}

function getRateValue(rate: SushiRate): string {
    return rate ? rate.toString() : SushiRate.EMPTY.toString();
}

function resolveRateOptions(): Array<SelectOption> {
    return [
        {name: '', value: SushiRate.EMPTY.toString()},
        resolveOption(SushiRate.LOW),
        resolveOption(SushiRate.MEDIUM),
        resolveOption(SushiRate.HIGH)
    ];
}

function resolveOption(rate: SushiRate): SelectOption {
    return {name: rate.toString(), value: rate.toString()};
}
