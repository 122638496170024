import {DeleteServiceResponse, service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {ChecklistItem} from '../types';

const CHECK_LIST_ITEM_SERVICE_NAME: 'checklist.item' = 'checklist.item';

export function insertChecklistItemService(data: ChecklistItem): Promise<ServiceResponseWithId> {
    return service.post(CHECK_LIST_ITEM_SERVICE_NAME, {data});
}

export function clearCheckListService(listIdToClear: number): Promise<ServiceResponseWithAffectedRow> {
    return service.put(CHECK_LIST_ITEM_SERVICE_NAME, {data: {listIdToClear}});
}

export function updateChecklistItemCheckedService(id: number, checked: boolean): Promise<ServiceResponseWithAffectedRow> {
    return service.put(CHECK_LIST_ITEM_SERVICE_NAME, {data: {id, checked}});
}

export function updateChecklistItemService(data: ChecklistItem): Promise<ServiceResponseWithAffectedRow> {
    return service.put(CHECK_LIST_ITEM_SERVICE_NAME, {data});
}

export function deleteChecklistItemService(id: number): Promise<DeleteServiceResponse> {
    return service.delete(CHECK_LIST_ITEM_SERVICE_NAME, {data: {id}});
}
