import React from 'react';
import {PropsWithChildren} from 'react';

interface RowProps extends PropsWithChildren {
    description?: string;
    rowClass?: string;
}

export const ERROR_CLASS: string = 'validation-error';

export class RowComponent extends React.Component<RowProps, {}> {
    render() {
        return (
            <row-component class={this.props.rowClass}>
                <div>
                    {this.props.description && <span>{this.props.description}</span>}
                    {this.props.children}
                </div>
                <div className={ERROR_CLASS}></div>
            </row-component>
        );
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'row-component': unknown;
        }
    }
}
