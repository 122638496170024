import React from 'react';
import {Icon, SvgButton} from '@mp/common/svg';
import {Recipe} from '../types';

export function RecipeHeader(props: {recipe: Recipe; multiplier?: number; onEdit?: () => void}): JSX.Element {
    const {recipe, multiplier = 1, onEdit} = props;
    return (
        <div className="recipe-header">
            {window.history.length > 1 && <SvgButton color="black" icon={Icon.ArrowLeft} onClick={() => window.history.back()} />}
            {onEdit && <SvgButton color="black" icon={Icon.Pencil} onClick={onEdit} />}
            <h2 style={{textAlign: 'center'}}>{`${recipe.name} (${recipe.count * multiplier})`}</h2>
        </div>
    );
}
