const SEPARATOR = '__';

export function resolveMovieTimeId(params: {movieId: string; startTime: string}): string {
    const {movieId, startTime} = params;
    if (!movieId || !startTime) {
        return null;
    }
    return `${movieId}${SEPARATOR}${startTime.replace(':', '')}`;
}

export function resolveMovieId(movieTimeId: string): string {
    return movieTimeId.split(SEPARATOR)[0];
}
