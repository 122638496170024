import React from 'react';
import {Link} from 'react-router-dom';
import {clickOutside} from '@mp/common/utils/event';
import {getUserProfile} from '@mp/common/cookies';
import {Icon, SvgButton} from '@mp/common/svg';
import {EventType} from '@mp/common/types';
import {Router} from '@mp/route';
import {logoutService} from '../user/service/logout.service';
import {isAdminOrLoggedUser, ORIGIN} from '../../global';
import {TileItemParams, tileItems} from './tileItems';

const MP_HEADER: 'mp-header' = 'mp-header';
const MP_HEADER_ICONS: 'mp-header-icons' = 'mp-header-icons';
const MP_HEADER_RIGHT: 'mp-header-icons-right' = 'mp-header-icons-right';
const MP_HEADER_USER_MENU: 'mp-header-user-menu' = 'mp-header-user-menu';

interface HeaderState {
    userMenuOpened: boolean;
}

interface HeaderProps {
    itemIds: Array<number>;
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {userMenuOpened: false};
        this.changeUserMenuOpenedState = this.changeUserMenuOpenedState.bind(this);
        this.onOutsideClick = this.onOutsideClick.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<HeaderProps>, prevState: Readonly<HeaderState>): void {
        if (this.state.userMenuOpened) {
            setTimeout(() => document.addEventListener(EventType.CLICK, this.onOutsideClick), 200);
        } else {
            document.removeEventListener(EventType.CLICK, this.onOutsideClick);
        }
    }

    public render() {
        const {name: userName, image: userImage} = getUserProfile();

        return (
            <div className={MP_HEADER}>
                {isAdminOrLoggedUser() && (
                    <>
                        <div className={MP_HEADER_ICONS}>
                            <ul>{this.getMenuItems()}</ul>
                            <div className={MP_HEADER_RIGHT}>
                                {userName && <div className="header-user-name">{userName}</div>}
                                {userImage ? (
                                    <img src={`${ORIGIN}/${userImage}`} onClick={this.changeUserMenuOpenedState} alt="u" />
                                ) : (
                                    <SvgButton icon={Icon.User} onClick={this.changeUserMenuOpenedState} />
                                )}
                            </div>
                        </div>
                        {this.state.userMenuOpened && this.renderUserMenu()}
                    </>
                )}
            </div>
        );
    }

    private onOutsideClick(event: Event) {
        if (clickOutside(event, MP_HEADER_USER_MENU)) {
            this.setState((prevState: HeaderState) => ({userMenuOpened: false}));
        }
    }

    private renderUserMenu(): JSX.Element {
        return (
            <div className={MP_HEADER_USER_MENU}>
                <Link to={Router.getUrlToSettingsPage()} onClick={this.changeUserMenuOpenedState}>
                    Ustawienia
                </Link>
                <div onClick={() => onLogout()}>Wyloguj</div>
            </div>
        );

        function onLogout(): void {
            logoutService().then(() => window.location.replace(Router.getUrlToLoginPage()));
        }
    }

    private changeUserMenuOpenedState() {
        this.setState((prevState: HeaderState) => ({userMenuOpened: !prevState.userMenuOpened}));
    }

    private getMenuItems(): JSX.Element {
        const headerItems: Array<TileItemParams> = this.props.itemIds.map((id) => tileItems.getAllTitleItems().find((f) => f.id === id));

        return <>{headerItems.map((tile) => this.renderMenuItem(tile))}</>;
    }

    private renderMenuItem(tileItem: TileItemParams): JSX.Element {
        return (
            <li key={tileItem.id}>
                <Link to={tileItem.route}>
                    <SvgButton icon={tileItem.svgIcon} />
                </Link>
            </li>
        );
    }
}
