export function addMinutes(minute: number): number {
    const module: number = minute % 5;
    const result: number = 5 - module + module + 5 * Math.floor(minute / 5);
    return result === 60 ? 0 : result;
}

export function subMinutes(minute: number): number {
    const module: number = minute % 5;
    if (minute === 0) {
        return 55;
    }
    if (module === 0) {
        return minute - 5;
    }
    return minute - module;
}
