import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {addMonthAndSetFirstDay, addYearAndSetFirstDay, getMonthName} from '@mp/common/utils/date';
import {InputData} from '@mp/common/components/form-input/types';
import {isEmpty, isNotEmpty} from '@mp/common/utils/array';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {Icon, SvgButton} from '@mp/common/svg';
import {Router} from '@mp/route';
import {CalendarDate, CalendarEventType, CalendarRepeatType, Grid, GridCell, ViewMode} from '../types';
import {resolveEventTypeOptions} from '../utils/resolveEventTypeOptions';
import {resolveRepeatTypeOptions} from '../utils/resolveRepeatTypeOptions';
import {addDateService, updateDateService} from '../services';
import {PreviewDatesView} from './PreviewDatesView';
import {WEEK_DAY_SYMBOLS} from '../gridDateUtils';
import {resolveGrid} from '../gridUtils';
import {DetailsList} from './DatesList';
import {isAdmin} from '../../../global';

const MP_CALENDAR: 'mp-calendar' = 'mp-calendar';

interface CalendarComponentState {
    displayedDate: Date;
    grid: Grid;
    selectedCell: GridCell;
    calendarDateToEdit: CalendarDate;
    viewMode: ViewMode;
    message: string;
}

interface CalendarProps {
    calendarDates: Array<CalendarDate>;
    year: number;
    month: number;
}

export class CalendarComponent extends React.Component<CalendarProps, CalendarComponentState> {
    constructor(props: CalendarProps) {
        super(props);
        this.state = {
            displayedDate: null,
            grid: null,
            selectedCell: null,
            calendarDateToEdit: null,
            viewMode: ViewMode.Calendar,
            message: null
        };
    }

    public componentDidMount() {
        this.setDisplayedDateAndResolveGrid();
    }

    public componentDidUpdate(prevProps: Readonly<CalendarProps>): void {
        const {year, month} = this.props;
        if (prevProps.year !== year || prevProps.month !== month) {
            this.setDisplayedDateAndResolveGrid();
        }
    }

    public render() {
        const {viewMode, displayedDate, message, grid} = this.state;

        if (grid == null || !displayedDate) {
            return <div />;
        }

        switch (viewMode) {
            case ViewMode.Calendar:
                return (
                    <div>
                        {message && <div>{message}</div>}
                        <div className={MP_CALENDAR}>
                            {this.renderNavigationBar()}
                            <div className="calendar-grid">
                                <div className="calendar-grid-row">{this.renderWeekDays()}</div>
                                {this.renderDays()}
                            </div>
                        </div>
                        <DetailsList
                            grid={grid}
                            displayedDate={this.state.displayedDate}
                            onDateClick={(gridCell) => this.changeViewMode(gridCell)}
                        />
                    </div>
                );
            case ViewMode.AddDate:
                return (
                    <FormsModal<CalendarDate>
                        title="Dodaj datę"
                        mode="add"
                        inputs={this.createFormInputs()}
                        handleClose={() => this.setState({viewMode: ViewMode.Calendar, selectedCell: null})}
                        onSendForm={(object) => addDateService(object).then((res) => res.success && window.location.reload())}
                    />
                );
            case ViewMode.EditDate:
                return (
                    <FormsModal<CalendarDate>
                        title="Edytuj datę"
                        mode="update"
                        inputs={this.createFormInputs()}
                        handleClose={() => this.setState({viewMode: ViewMode.Calendar, selectedCell: null, calendarDateToEdit: null})}
                        onSendForm={(object) => updateDateService(object).then((res) => res.success && window.location.reload())}
                    />
                );
            case ViewMode.PreviewDates:
                return (
                    <PreviewDatesView
                        onBackCallback={() => this.setState({viewMode: ViewMode.Calendar, selectedCell: null, calendarDateToEdit: null})}
                        onAddCallback={() => this.setState({viewMode: ViewMode.AddDate})}
                        onEditCallback={(calendarDate: CalendarDate) =>
                            this.setState({viewMode: ViewMode.EditDate, calendarDateToEdit: calendarDate})
                        }
                        selectedCell={this.state.selectedCell}
                        displayedDate={this.state.displayedDate}
                    />
                );
        }
    }

    private setDisplayedDateAndResolveGrid(): void {
        const {year, month} = this.props;
        const displayedDate: Date = new Date(year, month, 1);
        this.setState({displayedDate, grid: resolveGrid(displayedDate, this.props.calendarDates)});
    }

    private createFormInputs(): Array<InputData<CalendarDate>> {
        const {id, isPublic, name, type, repeat, dateTo, timeFrom, timeTo, originDate} = this.state.calendarDateToEdit ?? {};

        const inputsData: Array<InputData<CalendarDate>> = [
            {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: name ?? '', validation: {minLength: 1, maxLength: 50}},
            {
                id: 'type',
                displayName: 'Wydarzenie',
                type: 'select',
                defaultValue: type ?? CalendarEventType.EVENT,
                selectOptions: resolveEventTypeOptions()
            },
            {id: 'date', displayName: 'Data', type: 'date', defaultValue: originDate ?? this.state.selectedCell.date},
            {id: 'dateTo', displayName: 'Data (do)', type: 'date', defaultValue: dateTo ?? null},
            {id: 'timeFrom', displayName: 'Godzina (start)', type: 'time', defaultValue: timeFrom ?? ''},
            {id: 'timeTo', displayName: 'Godzina (koniec)', type: 'time', defaultValue: timeTo ?? ''},
            {
                id: 'repeat',
                displayName: 'Powtarzanie',
                type: 'select',
                defaultValue: repeat ?? CalendarRepeatType.NONE,
                selectOptions: resolveRepeatTypeOptions()
            },
            {id: 'isPublic', displayName: 'Widoczne dla wszystkich', type: 'checkbox', defaultValue: isPublic ?? !isAdmin()}
        ];

        if (id) {
            inputsData.unshift({id: 'id', displayName: '', type: 'hidden', defaultValue: id});
        }

        return inputsData;
    }

    private renderNavigationBar(): JSX.Element {
        const displayedDate: Date = this.state.displayedDate;

        return (
            <div className="calendar-navigation-bar">
                <Link to={Router.getUrlToCalendarPage(resolveYearParams(-1))}>
                    <SvgButton icon={Icon.AngleDoubleLeft} />
                </Link>
                <Link to={Router.getUrlToCalendarPage(resolveMonthParams(-1))}>
                    <SvgButton icon={Icon.AngleLeft} />
                </Link>
                <span>{`${getMonthName(displayedDate)}, ${displayedDate.getFullYear()}`}</span>
                <Link to={Router.getUrlToCalendarPage(resolveMonthParams())}>
                    <SvgButton icon={Icon.AngleRight} />
                </Link>
                <Link to={Router.getUrlToCalendarPage(resolveYearParams())}>
                    <SvgButton icon={Icon.AngleDoubleRight} />
                </Link>
            </div>
        );

        function resolveYearParams(yearToAdd: number = 1): {year: number; month: number} {
            const date: Date = addYearAndSetFirstDay(displayedDate, yearToAdd);
            return {year: date.getFullYear(), month: date.getMonth()};
        }

        function resolveMonthParams(monthToAdd: number = 1): {year: number; month: number} {
            const date: Date = addMonthAndSetFirstDay(displayedDate, monthToAdd);
            return {year: date.getFullYear(), month: date.getMonth()};
        }
    }

    private renderWeekDays(): Array<JSX.Element> {
        return WEEK_DAY_SYMBOLS.map((day, i) => <p key={i}>{day}</p>);
    }

    private renderDays(): Array<JSX.Element> {
        const {grid} = this.state;

        const cellElements: Array<JSX.Element> = grid.map((gridCell, i) => {
            const allDatesCount: number = gridCell.dates?.length ?? 0;
            const onlyOwnDatesCount: number = gridCell.dates?.filter(({isOwn}) => isOwn).length ?? 0;

            const cellClass: string = classNames({
                'cell-current-day': gridCell.isCurrentDate,
                'cell-event': isNotEmpty(gridCell.dates),
                // 'cell-only-own': !day.otherMonth && allDatesCount === onlyOwnDatesCount,
                'cell-only-not-own': allDatesCount > 0 && onlyOwnDatesCount === 0,
                // 'cell-mixed': !day.otherMonth && allDatesCount - onlyOwnDatesCount > 0 && onlyOwnDatesCount > 0,
                'cell-other-month': gridCell.monthType !== 'current'
            });

            return (
                // eslint-disable-next-line
                <a key={`day-${i}`} className={cellClass} onClick={() => this.changeViewMode(gridCell)}>
                    {gridCell.date.getDate()}
                </a>
            );
        });

        const rowElements: Array<JSX.Element> = [];
        for (let i: number = 0; i < Math.ceil(cellElements.length / 7); i++) {
            rowElements.push(
                <div key={`row-${i}`} className="calendar-grid-row">
                    {cellElements.slice(i * 7, (i + 1) * 7)}
                </div>
            );
        }
        return rowElements;
    }

    private changeViewMode(gridCell: GridCell): void {
        let viewMode: ViewMode;
        if (!isEmpty(gridCell.dates)) {
            viewMode = ViewMode.PreviewDates;
        } else {
            viewMode = ViewMode.AddDate;
        }

        this.setState({viewMode, selectedCell: gridCell});
    }
}
