import {AxiosResponse} from 'axios';
import {service} from '@mp/common/api';
import {Checklist} from '../types';

export interface LoadChecklistServiceResponse {
    categories: Array<Checklist>;
}

export function loadChecklistsService(): Promise<LoadChecklistServiceResponse> {
    return service
        .get<LoadChecklistServiceResponse>('checklist.load')
        .then((result: AxiosResponse<LoadChecklistServiceResponse>) => result.data);
}
