import {InputData, SelectOption} from '@mp/common/components/form-input/types';
import type {WinesServiceResult} from '../services/wine.service';
import {Wine} from '../types';
import {t} from '../i18n';

export function getInputsData(winesData: WinesServiceResult, selectedWine?: Wine): Array<InputData<Wine>> {
    const {id, name, type, color, country, dryness, price, rankingValue, strength, vineStock, imageLink} = selectedWine ?? {};
    const {vineStockSuggestions, countrySuggestions, colorsSuggestions, drynessSuggestions, typeSuggestions} = winesData;
    const ADD_INPUTS: Array<InputData<Wine>> = [
        {id: 'name', displayName: t.name, type: 'text', defaultValue: name ?? '', validation: {minLength: 3, maxLength: 50}},
        {id: 'price', displayName: t.price, type: 'text-number', defaultValue: price ?? null},
        {
            id: 'country',
            displayName: t.originCountry,
            type: 'select',
            defaultValue: country ?? '',
            selectOptions: resolveOptions(countrySuggestions)
        },
        {id: 'color', displayName: t.color, type: 'select', defaultValue: color ?? '', selectOptions: resolveOptions(colorsSuggestions)},
        {
            id: 'vineStock',
            displayName: t.vineStock,
            type: 'select',
            defaultValue: vineStock ?? '',
            selectOptions: resolveOptions(vineStockSuggestions)
        },
        {
            id: 'dryness',
            displayName: t.dryness,
            type: 'select',
            defaultValue: dryness ?? '',
            selectOptions: resolveOptions(drynessSuggestions)
        },
        {id: 'type', displayName: t.type, type: 'select', defaultValue: type ?? '', selectOptions: resolveOptions(typeSuggestions)},
        {id: 'strength', displayName: t.strength, type: 'text-number', defaultValue: strength ?? null, validation: {minLength: 1}},
        {id: 'rankingValue', displayName: t.rankingValue, type: 'text-number', defaultValue: rankingValue ?? null},
        {id: 'imageLink', displayName: t.imageLink, type: 'text', defaultValue: imageLink}
    ];
    if (id) {
        ADD_INPUTS.push({id: 'id', displayName: null, type: 'hidden', defaultValue: id});
    }
    return ADD_INPUTS;
}
function resolveOptions(strings: Array<string>): Array<SelectOption> {
    const result: Array<SelectOption> = strings.map((s) => ({name: s, value: s}));
    if (!result.find(({name}) => name === '') && !result.find(({name}) => name === null)) {
        result.unshift({name: '', value: null});
    }
    return result;
}
