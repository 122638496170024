import {Expense, ExpenseCategory} from '../types';

export type ValuesForTypesMap = Map<number, number>;

export function getValuesForTypes(expenses: Array<Expense>, expenseCategories: Array<ExpenseCategory>): ValuesForTypesMap {
    const map: ValuesForTypesMap = new Map<number, number>();
    expenseCategories.forEach((type) => map.set(type.id, 0));

    expenses.forEach((e: Expense) => {
        const value: number = map.get(e.typeId);
        map.set(e.typeId, value + e.value);
    });

    return map;
}
