import React from 'react';
import {Link} from 'react-router-dom';
import {toDateString} from '@mp/common/utils/converters';
import {Router} from '@mp/route';
import {TravelData} from '../services';

interface TravelListParams {
    travels: Array<TravelData>;
    onTravelListItemClick: (travelId: number) => void;
}
export function TravelList(params: TravelListParams): JSX.Element {
    const {travels, onTravelListItemClick} = params;

    return (
        <div className="travels-list">
            {travels.map((travel) => {
                const travelDate: string = `(${toDateString(travel.start)} - ${toDateString(travel.end)})`;

                return (
                    <div key={travel.id} className="travels-list-item">
                        <Link onClick={() => onTravelListItemClick(travel.id)} to={Router.getUrlToTravelPaymentPage({id: travel.id})}>
                            <span className="travels-list-item-name">{travel.name}</span>
                            <span className="travels-list-item-date">{travelDate}</span>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
}
