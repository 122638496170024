export interface Sushi {
    id: number;
    restaurantId: number;
    name: string;
    ingredients: string;
    rateK: SushiRate;
    rateM: SushiRate;
}

export interface Restaurant {
    id: number;
    name: string;
    description: string;
    rate: number;
}

export enum SushiRate {
    EMPTY = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3
}
