import Axios, {AxiosProgressEvent, AxiosRequestConfig} from 'axios';
import {isEmpty} from '@mp/common/utils/array';
import {createServiceFullName} from '@mp/common/api';
import {getFileExtension} from '../utils/getFileExtension';
import {megaBytesToBytes} from '../utils/megaBytesToBytes';

export function updateFileService(
    file: File,
    folderName: string,
    maxFileSizeMB: number,
    allowedExtensions: Array<string>,
    onUploadProgressCallback: (percent: number) => void,
    onUploadFinishedCallback: () => void
): void {
    const data: FormData = new FormData();
    const errorMessage: Array<string> = [];

    if (file.size > megaBytesToBytes(maxFileSizeMB)) {
        errorMessage.push(`Maksymalny rozmiar pliku: ${maxFileSizeMB}MB`);
    }
    const extension: string = getFileExtension(file.name);
    if (!allowedExtensions.includes(extension)) {
        errorMessage.push(`Niedozwolone rozszerzenie: ${extension}`);
    }

    if (isEmpty(errorMessage)) {
        data.append('file', file, file.name);
        if (folderName) {
            data.append('folder', folderName);
        }

        const options: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const {loaded, total} = progressEvent;
                const percent: number = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    onUploadProgressCallback(percent);
                }
            }
        };

        Axios.post(createServiceFullName('files.upload', 'pages'), data, options).then((res) => {
            if (!res.data.success) {
                alert(res.data.message);
            }
            onUploadFinishedCallback();
        });
    } else {
        alert(errorMessage.join('\n'));
    }
}
