const FILM_WEB_HOST: string = 'https://www.filmweb.pl/';

export function createLinkToMovie(fid: string | number): string {
    return `${FILM_WEB_HOST}film?id=${fid}`;
}

export function createLinkToPerson(personId: string | number): string {
    return `${FILM_WEB_HOST}person?id=${personId}`;
}

export function createLinkToSearch(query: string): string {
    return `${FILM_WEB_HOST}search#/?query=${query}`;
}
