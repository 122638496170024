export const SEASON_TEMPLATE: string = '{s}';
export const EPISODE_TEMPLATE: string = '{e}';

export interface RawSeries {
    id: number;
    fid: number;
    hasSeasons: boolean;
    day: number;
    name: string;
    season: number;
    episode: number;
    link: string;
    visible: boolean;
    end: boolean;
    nextAirDate: string;
}

export type SeriesDTO = RawSeries;

export interface Series extends RawSeries {
    checkNewEpisodeState: CheckNewEpisodeType;
    nextEpisode?: FilmWebSeries;
    nextEpisodeIdWithDate: EpisodeIdWithDate;
}

interface FilmWebSeries {
    date: string;
    title: string;
    visible: boolean;
}

export type CheckNewEpisodeType = CheckNewEpisodeState | Date;

export enum CheckNewEpisodeState {
    LOADING = 'loading',
    NO_DATE = 'no-date'
}

export interface EpisodeIdWithDate {
    id: string;
    date: Date;
}
