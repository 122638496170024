import {isPositiveNumber} from '../../../utils/string';
import {lastElement} from '../../../utils/array';
import {ResultListItem} from '../types';

export function getResultListItem(itemName: string): ResultListItem {
    const SPLIT_CHAR: string = ' ';
    let name = itemName.trim().toLowerCase();
    const nameFrags: Array<string> = name.split(SPLIT_CHAR);
    let count: number = 1;
    if (nameFrags.length > 0) {
        if (isPositiveNumber(lastElement(nameFrags))) {
            count = parseInt(lastElement(nameFrags), 10);
            nameFrags.pop();
            name = nameFrags.join(SPLIT_CHAR);
        }
    }

    return {name, words: nameFrags, count};
}
