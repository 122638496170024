interface Color {
    light: string;
    dark: string;
}

const ID_TO_COLORS_MAP: Map<number, Color> = new Map<number, Color>([
    [1, {light: '#fff5f5', dark: '#f2e9e9'}],
    [2, {light: '#f4fce3', dark: '#e8efd7'}],
    [3, {light: '#f3f0ff', dark: '#e8e6f7'}],
    [4, {light: '#fff4e6', dark: '#f2e8da'}],
    [5, {light: '#dbe9f2', dark: '#c7dceb'}],
    [6, {light: '#ffffce', dark: '#faf3a0'}]
]);

export function getDarkColor(id: number) {
    return ID_TO_COLORS_MAP.get(id).dark;
}

export function getLightColor(id: number) {
    return ID_TO_COLORS_MAP.get(id).light;
}
