import {DartsGameData} from '../types';

const STORAGE_ID: string = 'darts-game';

export function saveInLocalStorage(gameData: DartsGameData): void {
    localStorage.setItem(STORAGE_ID, JSON.stringify(gameData));
}

export function loadFromLocalStorage(): DartsGameData | null {
    try {
        return JSON.parse(localStorage.getItem(STORAGE_ID));
    } catch {
        return null;
    }
}
