import {Context, createContext} from 'react';
import {Item} from '@mp/common/components/suggestion-list/types';
import {ShoppingListItem} from '../../shopping-list/types';

export type RecipesPageContextType = {
    reloadData: () => void;
    shoppingItems: Array<Item>;
    shoppingList: Array<ShoppingListItem>;
};
export const RecipesPageContext: Context<RecipesPageContextType> = createContext({
    shoppingItems: [],
    shoppingList: [],
    reloadData: null
});
