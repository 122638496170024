export const GLOBAL_ERROR_ID: string = 'global-error';
export const GLOBAL_PAGE_CONTENT_CLASS: string = 'global-page-content';

export const MONTH_NAMES: Array<string> = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
];

export enum ViewMode {
    Normal,
    Add,
    Edit
}
