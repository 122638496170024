export interface TravelPayment {
    id: number;
    date: Date;
    travelNameId: number;
    name: string;
    description: string;
    valueSum: number;
    valueK: number;
    valueM: number;
    notEditable: boolean;
}

export type TravelPaymentsMap = Map<string, Array<TravelPayment>>;

export enum ViewMode {
    List,
    Normal,
    Edit,
    Add
}
