import {AxiosResponse} from 'axios';
import {service, ServiceResponseWithError} from '@mp/common/api';
import {Icon} from '@mp/common/svg-icon';
import {ShoppingCategory} from '../types';

interface ShoppingCategoryDTO {
    id: string;
    category: string;
    icon: string;
}

export function loadCategoriesService(): Promise<ServiceResponseWithError<Array<ShoppingCategory>>> {
    return service
        .get<Array<ShoppingCategoryDTO>>('shoppingList.loadCategories')
        .then((response: AxiosResponse<Array<ShoppingCategoryDTO>>) => ({
            data: resolveShoppingItems(response.data)
        }));
}

function resolveShoppingItems(rawItems: Array<ShoppingCategoryDTO>): Array<ShoppingCategory> {
    return rawItems.map((item) => ({
        id: Number(item.id),
        category: item.category,
        icon: item.icon as Icon
    }));
}
