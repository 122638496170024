import {service} from '@mp/common/api';
import {FilmInfo} from '../types';

interface FilmInfoDTO {
    id: string;
    title: string;
    originalTitle: string;
    year: string;
    type: string;
    subType: string;
    posterPath: string;
    _exist: null;
    _watched: null;
}

export function loadFilmInfoService(movieId: string | number): Promise<FilmInfo> {
    return service
        .get<FilmInfoDTO>('loadFilmInfo', {data: {id: movieId}, prefix: 'filmweb'})
        .then((response) => resolveFilmInfoServiceResult(response.data));
}

function resolveFilmInfoServiceResult(rawResults: FilmInfoDTO): FilmInfo {
    return {
        id: Number(rawResults.id),
        title: rawResults.title,
        originalTitle: rawResults.originalTitle,
        type: rawResults.type,
        subType: rawResults.subType,
        year: Number(rawResults.year),
        posterPath: rawResults.posterPath?.replace('$', '2') ?? null,
        exist: rawResults._exist,
        toWatch: rawResults._exist && !rawResults._watched,
        watched: rawResults._watched
    };
}
