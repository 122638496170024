export type Films = Array<Film>;
export type SearchMovieResult = Film;

export interface FilmCriticsRating {
    rateValue: string;
    rateCount: string;
}

export enum InfoType {
    Character = 'character',
    Film = 'film',
    Person = 'person',
    Serial = 'serial'
}

export interface Film {
    fid: number;
    type: InfoType;
    matchedTitle: string;
    matchedLang: string;
    filmMainCast: Array<FilmMainCast>;
    info: FilmInfo;
    rating: FilmRating;
    criticsRating?: FilmCriticsRating;
}

interface FilmMainCast {
    id: number;
    name: string;
}

export interface FilmInfo {
    id: number;
    title: string;
    originalTitle: string;
    year: number;
    type: string;
    subType: string;
    posterPath: string;

    exist: boolean;
    toWatch: boolean;
    watched: boolean;
}

export interface FilmRating {
    id: number;
    count: number;
    rate: number;
    countWantToSee: number;
}
