import {ExpensesTab, Router} from '@mp/route';
import {TabItem} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {ExpensesPageProps} from '../types';

export function getNavigationTabs(props: ExpensesPageProps): Array<TabItem> {
    const {selectedTab, year, month} = props;
    const isYearTab: boolean = selectedTab === ExpensesTab.YearSummary;

    const prevDate: Date = isYearTab ? new Date(year - 1, month - 1, 1) : new Date(year, month - 2, 1);
    const nowDate: Date = new Date(year, month - 1, 1);
    const nextDate: Date = isYearTab ? new Date(year + 1, month - 1, 1) : new Date(year, month, 1);

    return [
        {name: '<', url: Router.getUrlToExpensesPage(selectedTab, prevDate), selected: false},
        {
            name: 'Miesiąc',
            url: Router.getUrlToExpensesPage(ExpensesTab.MonthSummary, nowDate),
            selected: selectedTab === ExpensesTab.MonthSummary
        },
        {name: 'Lista', url: Router.getUrlToExpensesPage(ExpensesTab.List, nowDate), selected: selectedTab === ExpensesTab.List},
        {
            name: 'Rok',
            url: Router.getUrlToExpensesPage(ExpensesTab.YearSummary, nowDate),
            selected: selectedTab === ExpensesTab.YearSummary
        },
        {name: '>', url: Router.getUrlToExpensesPage(selectedTab, nextDate), selected: false}
    ];
}
