import './_sushiPage.scss';
import classNames from 'classnames';
import React from 'react';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {isEmpty} from '@mp/common/utils/array';
import {Icon, SvgButton} from '@mp/common/svg';
import {ViewMode} from '@mp/common/consts';
import {FILTER_PARAM} from '@mp/route';
import {addSushiService, editSushiService, loadSushiWithRestaurantsService} from '../services';
import {getEditInputsData} from '../utils/getInputsData';
import {Restaurant, Sushi, SushiRate} from '../types';
import {Loading} from '../../../components/loading';
import {RestaurantFilter} from './RestaurantFilter';
import {RatingStars} from './RatingStars';

const SUSHI_PAGE: 'sushi-page' = 'sushi-page';

export interface SushiPageState {
    sushi: Array<Sushi>;
    restaurants: Array<Restaurant>;
    selectedSushi: Sushi;
    viewMode: ViewMode;
}

export interface SushiPageProps {
    restaurantFilter: number;
}

export function SushiPage(): JSX.Element {
    return <SushiPageCC restaurantFilter={useNumberQueryParams(FILTER_PARAM)} />;
}

let _sushi: Array<Sushi>;
let _restaurants: Array<Restaurant>;

class SushiPageCC extends React.Component<SushiPageProps, SushiPageState> {
    constructor(props: SushiPageProps) {
        super(props);

        this.state = {
            sushi: [],
            restaurants: [],
            selectedSushi: null,
            viewMode: ViewMode.Normal
        };

        this.editSushi = this.editSushi.bind(this);
    }

    public componentDidMount() {
        this.loadSushi(false);
    }

    public render() {
        const {sushi, restaurants, viewMode} = this.state;

        if (isEmpty(sushi)) {
            return <Loading />;
        }

        return (
            <div className={SUSHI_PAGE}>
                <div className={`${SUSHI_PAGE}-toolbar`}>
                    <RestaurantFilter restaurantFilterId={this.props.restaurantFilter} restaurants={restaurants} />
                    <SvgButton icon={Icon.Plus} onClick={() => this.setState({viewMode: ViewMode.Add})} />
                </div>
                <div className={SUSHI_PAGE}>{this.getFilteredSushi().map((s) => this.renderShushiItem(s))}</div>
                {viewMode === ViewMode.Edit && this.renderEditModal()}
                {viewMode === ViewMode.Add && this.renderAddModal()}
            </div>
        );
    }

    private loadSushi(forceLoad: boolean): void {
        if (_sushi == null || _restaurants == null || forceLoad) {
            loadSushiWithRestaurantsService().then(({sushi, restaurants}) => {
                _sushi = sushi;
                _restaurants = restaurants;
                this.setState({sushi, restaurants});
            });
        } else {
            this.setState({sushi: _sushi, restaurants: _restaurants});
        }
    }

    private getFilteredSushi(): Array<Sushi> {
        const {restaurantFilter} = this.props;
        if (restaurantFilter) {
            return this.state.sushi.filter((f) => f.restaurantId === restaurantFilter);
        }
        return this.state.sushi;
    }

    private renderEditModal(): JSX.Element {
        return (
            <FormsModal<Sushi>
                title="Edycja sushi"
                mode="update"
                inputs={getEditInputsData(this.state.restaurants, this.state.selectedSushi)}
                handleClose={() => this.setNormalView()}
                onSendForm={(object) => this.updateRowService(object)}
            />
        );
    }

    private renderAddModal(): JSX.Element {
        return (
            <FormsModal<Sushi>
                title="Dodaj sushi"
                mode="add"
                inputs={getEditInputsData(this.state.restaurants)}
                handleClose={() => this.setNormalView()}
                onSendForm={(object) => this.addSushi(object)}
            />
        );
    }

    private setNormalView(): void {
        this.setState({viewMode: ViewMode.Normal, selectedSushi: null});
    }

    private updateRowService(sushi: Sushi): Promise<void> {
        return editSushiService(sushi).then(({success}) => {
            if (success) {
                this.loadSushi(true);
                this.setNormalView();
            }
        });
    }

    private addSushi(sushi: Sushi): Promise<void> {
        return addSushiService(sushi).then(({success}) => {
            if (success) {
                this.loadSushi(true);
                this.setNormalView();
            }
        });
    }

    private renderShushiItem(sushi: Sushi): JSX.Element {
        const {id, restaurantId, name, ingredients, rateM, rateK} = sushi;
        const {restaurantFilter} = this.props;
        const sushiName: string = restaurantId && !restaurantFilter ? `${this.getRestaurantNameById(restaurantId)}: ${name}` : name;

        return (
            <div key={id} className={`${SUSHI_PAGE}__item`}>
                <div className={`${SUSHI_PAGE}__item__name`} onDoubleClick={() => this.editSushi(sushi)}>
                    <span>{sushiName}</span>
                    <span className={`${SUSHI_PAGE}__item__rating`}>
                        <span className={classNames(`${SUSHI_PAGE}__item__rating__k`, {disabled: rateK === 0})}>
                            {this.renderRating(rateK)}
                        </span>
                        <span className={classNames(`${SUSHI_PAGE}__item__rating__m`, {disabled: rateM === 0})}>
                            {this.renderRating(rateM)}
                        </span>
                    </span>
                </div>
                <div className={`${SUSHI_PAGE}__item__ingredienst`}>{ingredients}</div>
            </div>
        );
    }

    private editSushi(sushi: Sushi): void {
        this.setState({viewMode: ViewMode.Edit, selectedSushi: sushi});
    }

    private renderRating(rate: SushiRate): JSX.Element {
        switch (rate) {
            case SushiRate.LOW:
                return <RatingStars rate={1} />;
            case SushiRate.MEDIUM:
                return <RatingStars rate={2} />;
            case SushiRate.HIGH:
                return <RatingStars rate={3} />;
            default:
                return <RatingStars rate={0} />;
        }
    }

    private getRestaurantNameById(id: number): string {
        return this.state.restaurants.find((f) => f.id === id).name;
    }
}
