import {toTimeInput} from '@mp/common/utils/converters';
import {resolveEventName} from './resolveEventTypeOptions';
import {CalendarDate, CalendarRepeatType} from '../types';

interface FullNameOptions {
    showEventName?: boolean;
    showRepeatName?: boolean;
    displayedDate?: Date;
}

export function resolveFullEventName(calendarDate: CalendarDate, options?: FullNameOptions): string {
    const {showEventName = false, showRepeatName = false, displayedDate = null} = options ?? {};
    const {name: rawName, type, timeFrom, timeTo, date, repeat, showYears} = calendarDate;

    const prefixName: string = resolvePrefixName();
    const name: string = showEventName && prefixName ? `${prefixName} ${rawName}` : rawName;

    if (timeFrom && timeTo) {
        return `${toTimeInput(timeFrom)}-${toTimeInput(timeTo)} ${name}`;
    }

    if (timeFrom) {
        return `${toTimeInput(timeFrom)} ${name}`;
    }

    return name;

    function resolvePrefixName(): string {
        return `${resolveEventName(type, true)}${resolveYears()}${resolveRepeatName()}`;
    }

    function resolveRepeatName(): string {
        if (showRepeatName && repeat === CalendarRepeatType.MONTH_EXACT_DAY) {
            return ` (co miesiąc)`;
        }
        return '';
    }

    function resolveYears(): string {
        if (showYears && displayedDate) {
            return ` (${displayedDate.getFullYear() - date.getFullYear()})`;
        }
        return '';
    }
}
