import './LinksPage.scss';
import React, {useEffect, useState} from 'react';
import {loadLinksService} from '../services/links.service';

const LINKS_PAGE: 'mp-links-page' = 'mp-links-page';

export function LinksPage(): JSX.Element {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        loadLinksService().then((response) => setLinks(response.data));
    }, []);

    return (
        <div className={LINKS_PAGE}>
            {links.map((link) => (
                <div key={link.id} className={`${LINKS_PAGE}-link`}>
                    <a href={link.link}>{link.name || link.link}</a>
                </div>
            ))}
        </div>
    );
}
