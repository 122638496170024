import {AxiosResponse} from 'axios';
import {service} from '@mp/common/api';

type LoginServiceDTO = {success: boolean};

interface LoginServiceParams {
    login: string;
    password: string;
}

export async function loginService(params: LoginServiceParams): Promise<LoginServiceDTO> {
    const {login, password} = params;

    return service
        .get<LoginServiceDTO>('login', {data: {login: btoa(login), password: btoa(password)}})
        .then((response: AxiosResponse<LoginServiceDTO>) => response.data)
        .catch(() => ({success: false}));
}
