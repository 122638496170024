import './_snackBar.scss';
import './_apartmentExpenses.scss';
import {NavigateFunction} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {AxiosError} from 'axios';
import React from 'react';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {getMonthName, isCurrentMonth, isCurrentYear} from '@mp/common/utils/date';
import {Button} from '@mp/common/components/button/ui/Button';
import {isEmpty, lastElement} from '@mp/common/utils/array';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {toDate} from '@mp/common/utils/converters';
import {Icon, SvgButton} from '@mp/common/svg';
import {ViewMode} from '@mp/common/consts';
import {ID_PARAM, Router, YEAR_PARAM} from '@mp/route';
import {Modal} from '@mp/common/modals/Modal';
import {updateApartmentExpensesService, addApartmentExpensesService, loadApartmentExpensesService} from './services';
import {getEditInputsData} from './utils/getEditInputsData';
import {ExpensesSummary} from './ui/ExpensesSummary';
import {ExpensesTable} from './ui/ExpensesTable';
import {Loading} from '../../components/loading';
import {PaymentRow} from './types';
import {t} from './i18n';

interface ApartmentExpensesPageProps {
    navigate: NavigateFunction;
    expenseId: number;
    year: number;
}

interface ApartmentExpensesState {
    expenses: Array<PaymentRow>;
    previousExpense: PaymentRow;
    currentMonthNotExist: boolean;
    selectedRow: PaymentRow;
    viewMode: ViewMode;
}

export function ApartmentExpensesPage(): JSX.Element {
    return (
        <ApartmentExpensesPageCC
            year={useNumberQueryParams(YEAR_PARAM)}
            expenseId={useNumberQueryParams(ID_PARAM)}
            navigate={useNavigate()}
        />
    );
}

class ApartmentExpensesPageCC extends React.Component<ApartmentExpensesPageProps, ApartmentExpensesState> {
    private readonly currentYear: number;

    constructor(props: ApartmentExpensesPageProps) {
        super(props);
        this.currentYear = new Date().getFullYear();

        this.state = {
            expenses: null,
            previousExpense: null,
            currentMonthNotExist: null,
            viewMode: ViewMode.Normal,
            selectedRow: null
        };
    }

    public componentDidMount() {
        this.loadData(this.props.year);
    }

    public componentDidUpdate(prevProps: Readonly<ApartmentExpensesPageProps>): void {
        if (this.props.year !== prevProps.year) {
            this.loadData(this.props.year);
        }
    }

    public render() {
        const {expenseId, year, navigate} = this.props;

        if (expenseId && year) {
            const expense: PaymentRow = this.state.expenses?.find(({id}) => id === expenseId);
            if (expense) {
                const indexOf: number = this.state.expenses.indexOf(expense);
                const date: Date = new Date(expense.dateString);

                return (
                    <Modal
                        title={`${getMonthName(date)} ${date.getFullYear()}`}
                        handleClose={() => navigate(Router.getUrlToApartmentExpensesPage({year: this.props.year}))}
                    >
                        <ExpensesSummary
                            expenses={[this.state.expenses[indexOf - 1], expense]}
                            previousYearExpense={this.state.previousExpense}
                            showTitle={false}
                        />
                    </Modal>
                );
            } else {
                return <Loading />;
            }
        }

        if (this.state.viewMode === ViewMode.Normal) {
            return this.renderNormalView();
        } else if (this.state.viewMode === ViewMode.Edit) {
            return (
                <FormsModal<PaymentRow>
                    title="Edytuj wiersz"
                    mode="update"
                    inputs={getEditInputsData(this.state.selectedRow)}
                    handleClose={() => this.setNormalView()}
                    onSendForm={(object) => this.updateRowService(object)}
                />
            );
        }
    }

    private loadData(year: number) {
        loadApartmentExpensesService(year).then((response) => {
            const {expenses} = response ?? {};
            const lastDate: Date = !isEmpty(expenses) ? toDate(lastElement(expenses).dateString) : null;
            const currentMonthNotExist: boolean = (isCurrentYear(lastDate) && !isCurrentMonth(lastDate)) || isEmpty(expenses);

            this.setState({expenses, currentMonthNotExist, previousExpense: response.previousExpense});
        });
    }

    private updateRowService(object: object): Promise<void> {
        return updateApartmentExpensesService(object)
            .then(() => this.loadData(this.props.year))
            .then(() => this.setNormalView())
            .catch((error: AxiosError) => alert(error.response.data));
    }

    private setNormalView() {
        this.setState({viewMode: ViewMode.Normal, selectedRow: null});
    }

    private renderNormalView(): JSX.Element {
        if (!this.state.expenses) {
            return <Loading />;
        }
        return (
            <div className="mp-apartment-expenses-page">
                {this.renderNavigation()}
                {!isEmpty(this.state.expenses) ? (
                    <>
                        <ExpensesTable
                            expenses={this.state.expenses}
                            onEditHandler={(selectedRow) => this.setState({viewMode: ViewMode.Edit, selectedRow})}
                        />
                        <div className="cost">
                            <ExpensesSummary expenses={this.state.expenses} previousYearExpense={this.state.previousExpense} />
                        </div>
                    </>
                ) : (
                    <span className="no-data">{t.noData}</span>
                )}

                {this.state.currentMonthNotExist && (
                    <Button
                        title="Dodaj miesiąc"
                        onClick={() => addApartmentExpensesService().then(() => this.loadData(this.props.year))}
                    />
                )}
            </div>
        );
    }

    private renderNavigation(): JSX.Element {
        return (
            <div className="year-navigation">
                {this.renderNavigationButton(false)}
                <span>{this.props.year}</span>
                {this.renderNavigationButton(true)}
            </div>
        );
    }

    private renderNavigationButton(isNext: boolean): JSX.Element {
        const icon: Icon = isNext ? Icon.ChevronRight : Icon.ChevronLeft;
        const value: number = isNext ? 1 : -1;

        if (isNext) {
            if (this.props.year >= this.currentYear) {
                return null;
            }
        } else {
            if (this.props.year <= 2018) {
                return null;
            }
        }

        return (
            <Link to={Router.getUrlToApartmentExpensesPage({year: this.props.year + value})}>
                <SvgButton icon={icon} />
            </Link>
        );
    }
}
