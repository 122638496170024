import './_watchedMovies.scss';
import React from 'react';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {replacePolishChars} from '@mp/common/utils/string';
import {createLinkToMovie} from '@mp/filmweb/utils/link';
import {getElements} from '@mp/common/utils/array';

export interface IWatchedMovies {
    id: number;
    t1: string;
    t2: string;
    y: number;
    fid: string;
}

export interface WatchedMoviesProps {
    maxRows: number;
    movies: Array<IWatchedMovies>;
}

interface WatchedMoviesState {
    filteredMovies: Array<IWatchedMovies>;
}

export class WatchedMovies extends React.Component<WatchedMoviesProps, WatchedMoviesState> {
    constructor(props: WatchedMoviesProps) {
        super(props);

        const {movies, maxRows} = this.props;
        this.state = {
            filteredMovies: getElements(movies, maxRows)
        };

        this.searchInTitle = this.searchInTitle.bind(this);
        this.searchInYear = this.searchInYear.bind(this);
    }

    render() {
        const {filteredMovies} = this.state;
        const {movies} = this.props;
        const info: string = `Wyświetlono ${filteredMovies.length} z ${movies.length} filmów`;
        return (
            <watched-movies>
                {this.renderSearchComponent()}
                <div className={'watched-movies-list'}>
                    {filteredMovies.map((m: IWatchedMovies) => {
                        return (
                            <div key={m.id}>
                                <a href={createLinkToMovie(m.fid)}>
                                    <span>{`${m.t1} (${m.y})`}</span>
                                    <span>{m.t2}</span>
                                </a>
                            </div>
                        );
                    })}
                </div>
                {movies.length >= this.props.maxRows && <span>{info}</span>}
            </watched-movies>
        );
    }

    private renderSearchComponent() {
        return (
            <div className="search">
                <TextInput placeholder="Tytuł filmu" onChange={(e) => this.searchInTitle(e)} />
            </div>
        );
    }

    private searchInTitle(event: React.ChangeEvent<HTMLInputElement>) {
        const value: string = event.target.value;
        const filteredMovies: Array<IWatchedMovies> = this.props.movies
            .filter((f) => this.includesNoCapital(f.t1, value) || this.includesNoCapital(f.t2, value))
            .slice(0, this.props.maxRows);
        this.setState({filteredMovies});
    }

    private searchInYear(event: React.ChangeEvent<HTMLInputElement>) {
        const value: string = event.target.value;
        const filteredMovies: Array<IWatchedMovies> = this.props.movies
            .filter((f) => (f.y + '').includes(value))
            .slice(0, this.props.maxRows);
        this.setState({filteredMovies});
    }

    private includesNoCapital(text: string, value: string): boolean {
        return replacePolishChars(text.toLowerCase()).includes(replacePolishChars(value.toLowerCase()));
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'watched-movies': React.HTMLAttributes<HTMLElement>;
        }
    }
}
