import classNames from 'classnames';
import React from 'react';
import {GLOBAL_PAGE_CONTENT_CLASS} from '@mp/common/consts';
import {clickOutside} from '@mp/common/utils/event';
import {Icon, SvgButton} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {EventType} from '@mp/common/types';

const MP_SETTING_MENU = 'mp-setting-menu';

export interface CustomMenuElement {
    label: string;
    onClick: () => void;
}

interface SettingMenuState {
    menuOpened: boolean;
    leftPosition: number;
    topPosition: number;
    fullScreenMode: boolean;
}

interface SettingMenuProps {
    title: string;
    menuWidth: number;
    onEditClick?: () => void;
    customMenuElements?: Array<CustomMenuElement>;
}

export class SettingMenu extends React.Component<SettingMenuProps, SettingMenuState> {
    private thisElement: HTMLDivElement;
    private contentElement: HTMLDivElement;

    constructor(props: SettingMenuProps) {
        super(props);

        this.onOutsideClick = this.onOutsideClick.bind(this);

        this.state = {
            menuOpened: false,
            leftPosition: undefined,
            topPosition: undefined,
            fullScreenMode: false
        };
    }

    public render() {
        const {menuOpened} = this.state;
        return (
            <div className={MP_SETTING_MENU} ref={(element: HTMLDivElement) => (this.thisElement = element)}>
                <SvgButton icon={Icon.Gear} onClick={() => this.setState({menuOpened: !menuOpened})} />
                {menuOpened && this.renderContent()}
            </div>
        );
    }

    public componentDidUpdate(prevProps: Readonly<SettingMenuProps>, prevState: Readonly<SettingMenuState>) {
        if (this.state.menuOpened && prevState.menuOpened === false) {
            setTimeout(() => document.addEventListener(EventType.CLICK, this.onOutsideClick), 100);

            const settingMenuIconRect: DOMRect = this.thisElement.getBoundingClientRect();
            const settingMenuContentRect: DOMRect = this.contentElement.getBoundingClientRect();
            const body: DOMRect = document.getElementsByClassName(GLOBAL_PAGE_CONTENT_CLASS)[0].getBoundingClientRect();

            if (body.width < settingMenuContentRect.right) {
                const willFitHorizontally: boolean = settingMenuContentRect.x > settingMenuContentRect.width;
                if (willFitHorizontally) {
                    this.setState({leftPosition: settingMenuIconRect.width / 2 - this.props.menuWidth});
                } else {
                    this.setState({fullScreenMode: true});
                }
            }

            if (body.height < window.scrollY + settingMenuContentRect.top + settingMenuContentRect.height) {
                const willFitVertically: boolean = settingMenuContentRect.top + window.scrollY > settingMenuContentRect.height;
                if (willFitVertically) {
                    this.setState({topPosition: -settingMenuContentRect.height + settingMenuIconRect.height / 2});
                } else {
                    this.setState({fullScreenMode: true});
                }
            }
        }
        if (this.state.menuOpened === false && prevState.menuOpened) {
            document.removeEventListener(EventType.CLICK, this.onOutsideClick);
            this.setState({leftPosition: undefined, topPosition: undefined, fullScreenMode: false});
        }
    }

    public componentWillUnmount() {
        document.removeEventListener(EventType.CLICK, this.onOutsideClick);
    }

    private renderContent(): JSX.Element {
        const {customMenuElements, title} = this.props;
        const {fullScreenMode} = this.state;
        return (
            <div
                className={classNames({
                    [`${MP_SETTING_MENU}-content`]: !fullScreenMode,
                    [`${MP_SETTING_MENU}-content-full-screen`]: fullScreenMode
                })}
                ref={(element: HTMLDivElement) => (this.contentElement = element)}
                style={{width: this.props.menuWidth, left: this.state.leftPosition, top: this.state.topPosition}}
            >
                {fullScreenMode && <div className={`${MP_SETTING_MENU}-content-full-screen-title`}>{title}</div>}
                {!isEmpty(customMenuElements) && customMenuElements.map((element) => this.renderMenuItem(element.onClick, element.label))}
            </div>
        );
    }

    private renderMenuItem(onClick: () => void, label: string): JSX.Element {
        return (
            <div key={label} className={`${MP_SETTING_MENU}-content-item`} onClick={() => this.setState({menuOpened: false}, onClick)}>
                {label}
            </div>
        );
    }

    private onOutsideClick(event: Event) {
        if (clickOutside(event, `${MP_SETTING_MENU}-content`)) {
            this.setState({menuOpened: false});
        }
    }
}
