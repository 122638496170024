import {Expense, ExpensesByDate} from './types';

export function resolveExpensesByDate(expenses: Array<Expense>): Array<ExpensesByDate> {
    const expencesByDate: Array<ExpensesByDate> = [];

    expenses.forEach((expence) => {
        const foundItem: ExpensesByDate = expencesByDate.find((f) => f.date.substr(0, 10) === expence.date.substr(0, 10));

        if (foundItem) {
            foundItem.expenses.push(resolveExpense(expence));
        } else {
            expencesByDate.push({
                date: expence.date.substr(0, 10),
                expenses: [resolveExpense(expence)]
            });
        }
    });

    expencesByDate.forEach((e) => {
        let value: number = 0;
        e.expenses.forEach((ex) => {
            value += Number(ex.value);
        });
        e.value = value;
    });

    function resolveExpense(expense: Expense): Expense {
        return {
            id: expense.id,
            typeId: expense.typeId,
            date: expense.date,
            value: expense.value,
            description: expense.description
        };
    }

    return expencesByDate;
}
