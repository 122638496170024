import './_calendarPage.scss';
import React from 'react';
import {CalendarComponent} from '../../components/calendar/ui/Calendar';
import {CalendarDate} from '../../components/calendar/types';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {MONTH_PARAM, YEAR_PARAM} from '@mp/route';

const MP_CALENDAR_PAGE: 'mp-calendar-page' = 'mp-calendar-page';

export interface CalendarPageState {
    calendarDates: Array<CalendarDate>;
    displayedDate: Date;
}

export interface CalendarPageProps {
    state: CalendarPageState;
    year: number;
    month: number;
}

export function CalendarPage(props: Pick<CalendarPageProps, 'state'>): JSX.Element {
    return <CalendarPageCC state={props.state} year={useNumberQueryParams(YEAR_PARAM)} month={useNumberQueryParams(MONTH_PARAM)} />;
}

class CalendarPageCC extends React.Component<CalendarPageProps, CalendarPageState> {
    constructor(props: CalendarPageProps) {
        super(props);

        this.state = this.props.state;
    }

    public render() {
        if (this.state.calendarDates) {
            const {year, month} = this.props;

            return (
                <div className={MP_CALENDAR_PAGE}>
                    <CalendarComponent calendarDates={this.state.calendarDates} year={year} month={month} />
                </div>
            );
        }
        return null;
    }
}
