import {Router, WishListTab} from '@mp/route';
import {TabItem} from '../../../../components/tabs-navigation/ui/TabsNavigation';
import {WishItem} from '../../types';

const TO_BUY: string = 'Do kupienia';
const BOUGHT: string = 'Kupione';

export function getNavigationTabs(selectedTab: WishListTab, wishItems: Array<WishItem>): Array<TabItem> {
    const toBuyWholeCost: Array<number> = [];
    const boughtWholeCost: Array<number> = [];
    wishItems.forEach((item) => {
        item.bought ? boughtWholeCost.push(item.price) : toBuyWholeCost.push(item.price);
    });

    return [
        {
            name: `${TO_BUY} ${getCost(toBuyWholeCost)}`,
            url: Router.getUrlToWishListPage(WishListTab.TO_BUY),
            selected: selectedTab === WishListTab.TO_BUY
        },
        {
            name: `${BOUGHT} ${getCost(boughtWholeCost)}`,
            url: Router.getUrlToWishListPage(WishListTab.BOUGHT),
            selected: selectedTab === WishListTab.BOUGHT
        }
    ];
}

function getCost(prices: Array<number>): string {
    return `(${prices.reduce((x, y) => x + y, 0).toLocaleString()} zł)`;
}
