import Cookies from 'universal-cookie';

interface CookieUserProfile {
    name: string;
    type: string;
    image: string;
}

export function getUserProfile(): CookieUserProfile {
    const cookies = new Cookies();

    const userProfile: CookieUserProfile = cookies.get('user_profile');
    if (userProfile) {
        return userProfile;
    }

    return {
        name: null,
        type: null,
        image: null
    };
}
