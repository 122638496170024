import './_subscriptionsPage.scss';
import React, {useEffect, useState} from 'react';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {LoadSubscriptionsResult, loadSubscriptionsService} from '../services/loadSubscriptions.service';
import {Loading} from '../../../components/loading';

export function SubscriptionsPage(): JSX.Element {
    const [serviceResult, setServiceResult] = useState<LoadSubscriptionsResult>();

    useEffect(() => {
        loadSubscriptionsService().then((result) => setServiceResult(result));
    }, []);

    if (serviceResult == null) {
        return <Loading />;
    }
    return (
        <div className="mp-subscriptions-page">
            <SimpleToolBar name="Subskrypcje" />
            <br />
            <div>{`Roczny koszt: ${serviceResult.totalCostPerYear.toFixed(2)}`}</div>
            <div>{`Miesięczny koszt: ${(serviceResult.totalCostPerYear / 12).toFixed(2)}`}</div>
            <br />
            {serviceResult.subscriptions.map((sub) => (
                <div key={sub.id} className="subscription">
                    <div>{sub.name}</div>
                    <div>
                        <span>{sub.daysToPayment ?? '?'} (dni)</span>
                        <span>{`${sub.constMonthly.toFixed(2)} zł`}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}
