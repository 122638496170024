import {round} from '@mp/common/utils/number';

const MEGA: number = 1024 * 1024;

export function getFileSize(bytes: number): string {
    if (bytes < 1024) {
        return `${round(bytes / 1024, 2)} KB`;
    } else {
        return `${round(bytes / MEGA, 2)} MB`;
    }
}
