import {Link} from 'react-router-dom';
import React from 'react';
import {ChangeEvent} from 'react';
import {Button} from '@mp/common/components/button/ui/Button';
import {SvgButton, Icon} from '@mp/common/svg';
import {Router} from '@mp/route';
import {resolveCategoryName} from '../resolveCategoryName';
import {RecipeCategory} from '../types';

const MP_RECIPES_TOOLBAR = 'mp-recipes-toolbar';

interface RecipesToolbarProps {
    allFilters: Array<RecipeCategory>;
    selectedFilter: RecipeCategory;
    searchPhrase: string;
    onAddButtonClick: () => void;
    onSearchTextChanged: (phrase: string) => void;
}

interface RecipesToolbarState {
    isOpened: boolean;
    selectedFilter: RecipeCategory;
}

export class RecipesToolbar extends React.Component<RecipesToolbarProps, RecipesToolbarState> {
    constructor(props: RecipesToolbarProps) {
        super(props);

        this.state = {
            isOpened: false,
            selectedFilter: this.props.selectedFilter
        };
    }

    render() {
        const {isOpened} = this.state;
        return (
            <MP_RECIPES_TOOLBAR>
                <div className={MP_RECIPES_TOOLBAR}>
                    <Button
                        title={`Filtr: ${resolveCategoryName(this.state.selectedFilter)}`}
                        className={`${MP_RECIPES_TOOLBAR}__filter-select`}
                        onClick={() => this.setState({isOpened: !isOpened})}
                    />
                    {this.renderSearchInpunt()}
                    <SvgButton icon={Icon.Plus} onClick={this.props.onAddButtonClick} />
                    {this.renderFilters()}
                </div>
            </MP_RECIPES_TOOLBAR>
        );
    }

    private renderFilters(): JSX.Element {
        if (this.state.isOpened) {
            return (
                <ul className={`${MP_RECIPES_TOOLBAR}__filters`}>
                    {this.renderFilter(null)}
                    {this.props.allFilters.map((filter: RecipeCategory) => this.renderFilter(filter))}
                </ul>
            );
        }
    }

    private renderFilter(category: RecipeCategory): JSX.Element {
        return (
            <Link key={category?.id ?? 0} to={Router.getUrlToRecipesPage({filter: category?.name})}>
                <li onClick={() => this.setState({selectedFilter: category ?? null, isOpened: false})}>{resolveCategoryName(category)}</li>
            </Link>
        );
    }

    private renderSearchInpunt(): JSX.Element {
        return (
            <div className={`${MP_RECIPES_TOOLBAR}__search`}>
                <input
                    value={this.props.searchPhrase}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.props.onSearchTextChanged(e.currentTarget.value)}
                    type="text"
                    autoCapitalize="none"
                    placeholder="szukaj..."
                />
            </div>
        );
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [MP_RECIPES_TOOLBAR]: React.HTMLAttributes<HTMLDivElement>;
        }
    }
}
