import {Crop} from 'react-image-crop';
import {CroppedImage} from '../types';

export function getCroppedImage(image: HTMLImageElement, crop: Crop, quality: number): CroppedImage | null {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const sourceX: number = crop.x * scaleX;
    const sourceY: number = crop.y * scaleY;
    const cropWidth: number = crop.width * scaleX;
    const cropHeight: number = crop.height * scaleY;

    const ctx = canvas.getContext('2d');
    try {
        ctx.drawImage(image, sourceX, sourceY, cropWidth, cropHeight, 0, 0, crop.width, crop.height);
    } catch (e) {
        alert((e as DOMException).message);
        return null;
    }

    const base64Image: string = canvas.toDataURL('image/webp', quality);

    return {base64: base64Image, height: Math.round(crop.height), width: Math.round(crop.width), size: base64Image.length};
}
