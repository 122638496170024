import {SelectOption} from '@mp/common/components/form-input/types';
import {CalendarRepeatType} from '../types';

const CALENDAR_REPEAT_TYPE_TO_NAME_MAP: Map<CalendarRepeatType, string> = new Map<CalendarRepeatType, string>([
    [CalendarRepeatType.YEAR, 'Co rok'],
    [CalendarRepeatType.MONTH_EXACT_DAY, 'Co miesiąc (dzień)'],
    // [CalendarRepeatType.MONTH_BEFORE_END, 'Co miesiąc (dzień od końca)'],
    [CalendarRepeatType.NONE, 'Nie powtarzaj']
]);

export function resolveRepeatTypeOptions(): Array<SelectOption> {
    const elements: Array<SelectOption> = [];
    CALENDAR_REPEAT_TYPE_TO_NAME_MAP.forEach((name: string, key: CalendarRepeatType) => {
        elements.push({name, value: key});
    });
    return elements;
}
