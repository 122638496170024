import {Score, ScoreFactor} from '../types';

export function resolveDisplayPoints(score: Partial<Score>): string {
    const {value, factor} = score;

    if (value == null) {
        return '';
    }
    if (value === 0) {
        return '0';
    }
    if (factor === ScoreFactor.Double) {
        return `D${value}`;
    }
    if (factor === ScoreFactor.Triple) {
        return `T${value}`;
    }
    return value.toString();
}
