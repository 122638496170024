import {InputData, SelectOption} from '@mp/common/components/form-input/types';
import {Expense, ExpenseCategory} from '../types';

export function getInputsData(expense: Expense, expenseCategories: Array<ExpenseCategory>): Array<InputData<Expense>> {
    const {id, typeId, date, value, description} = expense;
    return [
        {displayName: 'Id', id: 'id', type: 'hidden', defaultValue: id ?? ''},
        {displayName: 'Data', id: 'date', type: 'date-time', defaultValue: date ? new Date(date) : new Date()},
        {displayName: 'Koszt', id: 'value', type: 'text-number', defaultValue: value ?? null},
        {
            displayName: 'Kategoria',
            id: 'typeId',
            type: 'select-number',
            defaultValue: typeId ?? 1,
            selectOptions: getCategoryOptions()
        },
        {displayName: 'Opis', id: 'description', type: 'text', defaultValue: description ?? null}
    ];

    function getCategoryOptions(): Array<SelectOption> {
        const options: Array<SelectOption> = expenseCategories.map(({id: _id, name}) => ({name, value: _id.toString()}));
        options.unshift({name: '', value: null});
        return options;
    }
}
