import React, {useState} from 'react';
import {Button} from '@mp/common/components/button/ui/Button';
import {Score, ScoreFactor} from '../types';

interface KeyboardProps {
    onScoreClick: (score: Score) => void;
    onUndoClick: () => void;
    onResetClick: () => void;
}

export function DartsKeyBoard(props: KeyboardProps): JSX.Element {
    const [factor, setFactor] = useState<ScoreFactor>(ScoreFactor.Normal);

    function getClassName(_factor: ScoreFactor): string {
        return _factor === factor ? 'selected' : '';
    }

    function changeFactor(_factor: ScoreFactor): void {
        setFactor(_factor === factor ? ScoreFactor.Normal : _factor);
    }

    return (
        <div className="darts-keyboard">
            {new Array(21).fill(null).map((_, i) => (
                <Button key={i} title={i.toString()} onClick={() => handleScoreClick(i)} />
            ))}
            <div className="darts-keyboard-extra">
                <div>
                    <Button title="Double" onClick={() => changeFactor(ScoreFactor.Double)} className={getClassName(ScoreFactor.Double)} />
                    <Button title="Triple" onClick={() => changeFactor(ScoreFactor.Triple)} className={getClassName(ScoreFactor.Triple)} />
                </div>
                <div>
                    <Button title={25} onClick={() => handleScoreClick(25, true)} />
                    <Button title={50} onClick={() => handleScoreClick(50, true)} />
                </div>
                <div>
                    <Button title="Cofnij" onDoubleClick={props.onUndoClick} />
                    <Button title="Reset" onDoubleClick={props.onResetClick} />
                </div>
            </div>
        </div>
    );

    function handleScoreClick(value: number, ignoreFactor = false): void {
        props.onScoreClick({value, factor: ignoreFactor ? ScoreFactor.Normal : factor});
        setFactor(ScoreFactor.Normal);
    }
}
