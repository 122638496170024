import {DeleteServiceResponse, service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {CashEnvelope} from '../types';

const CASH_ENVELOPES_SERVICE_NAME: string = 'cashEnvelopes';

export function loadCashEnvelopesService(): Promise<Array<CashEnvelope>> {
    return service.get<Array<CashEnvelope>>(CASH_ENVELOPES_SERVICE_NAME).then((result) => result.data);
}

export function addCashEnvelopeService(data: CashEnvelope): Promise<ServiceResponseWithId> {
    return service.post(CASH_ENVELOPES_SERVICE_NAME, {data});
}

export function updateCashEnvelopeService(data: CashEnvelope): Promise<ServiceResponseWithAffectedRow> {
    return service.patch(CASH_ENVELOPES_SERVICE_NAME, {data});
}

export function deleteCashEnvelopeService(id: number): Promise<DeleteServiceResponse> {
    return service.delete(CASH_ENVELOPES_SERVICE_NAME, {data: {id}});
}
