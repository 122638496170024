import './_cashEnvelopesPage.scss';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {toCurrency} from '@mp/common/utils/number';
import {Router} from '@mp/route';
import {addCashEnvelopeService, loadCashEnvelopesService} from '../services/cashEnvelopes.service';
import {CashEnvelopePage} from './CashEnvelopePage';
import {Loading} from '../../../components/loading';
import {CashEnvelope} from '../types';
import {Envelope} from './Envelope';

export function CashEnvelopesPage(): JSX.Element {
    const {id} = useParams<{id}>();

    const [cashEnvelopes, setCashEnvelopes] = useState<Array<CashEnvelope>>(null);

    useEffect(() => init(), []);

    if (cashEnvelopes === null) {
        return <Loading />;
    }

    function init(): void {
        loadCashEnvelopesService().then(setCashEnvelopes);
    }

    const cashEnvelope: CashEnvelope = cashEnvelopes.find((envelope) => envelope.id === id);

    return cashEnvelope ? (
        <CashEnvelopePage cashEnvelope={cashEnvelope} onUpdate={init} />
    ) : (
        <CashEnvelopesList cashEnvelopes={cashEnvelopes} onUpdate={init} />
    );
}

function CashEnvelopesList({cashEnvelopes, onUpdate}: {cashEnvelopes: Array<CashEnvelope>; onUpdate: () => void}): JSX.Element {
    const totalValue: number = cashEnvelopes.map(({value}) => value).reduce((a, b) => a + b);

    function handleAddClick(): void {
        addCashEnvelopeService({id: null, value: 0, name: null}).then(({success}) => success && onUpdate());
    }

    return (
        <div className="cash-envelopes-page">
            <SimpleToolBar name="Koperty" onAddClick={handleAddClick} />
            <div className="cash-envelopes-page-header">{toCurrency(totalValue)}</div>
            <div className="cash-envelopes-page-grid">
                {cashEnvelopes.map((envelope) => (
                    <Link key={envelope.id} to={Router.getUrlToCashEnvelopesPage(envelope.id)}>
                        <Envelope cashEnvelope={envelope} />
                    </Link>
                ))}
            </div>
        </div>
    );
}
