import {AxiosResponse} from 'axios';
import {isNotEmptyString} from '@mp/common/utils/string';
import {service} from '@mp/common/api';
import {getAppSettings} from '../../../global';
import {FilmCriticsRating} from '../types';

interface CriticsRatingDTO {
    rateValue: string;
    rateCount: string;
}

export function loadCriticsRatingService(movieId: string | number): Promise<FilmCriticsRating> {
    if (!getAppSettings().moviesPageSearchIncludeCriticsRating) {
        return null;
    }
    return service
        .get<CriticsRatingDTO>('loadCriticsRating', {data: {movieId}, prefix: 'filmweb'})
        .then((result: AxiosResponse<CriticsRatingDTO>) => {
            return resolveData(result.data);
        });
}

function resolveData(rawData: CriticsRatingDTO): FilmCriticsRating {
    return {
        rateValue: rawData.rateValue,
        rateCount: isNotEmptyString(rawData.rateCount) ? rawData.rateCount.split(' ')[0] : ''
    };
}
