import './_expensesPage.scss';
import {useParams} from 'react-router-dom';
import React from 'react';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {ExpensesTab, MONTH_PARAM, YEAR_PARAM} from '@mp/route';
import {resolveMonthName} from '@mp/common/utils/date';
import {addExpenseService, loadExpensesService} from './services';
import {TabsNavigation} from '../../components/tabs-navigation/ui/TabsNavigation';
import {Expense, ExpenseCategory, ExpensesPageProps} from './types';
import {getNavigationTabs} from './utils/getNavigationTabs';
import {MonthExpensesList} from './ui/MonthExpensesList';
import {ExpensesSummary} from './ui/ExpensesSummary';
import {ToolsBar} from '../../components/tools-bar';
import {Loading} from '../../components/loading';
import {NumKeyboard} from './ui/NumKeyboard';

interface ExpensesPageState {
    isLoaded: boolean;
    expenses: Array<Expense>;
    yearExpenses: Array<Expense>;
    expenseCategories: Array<ExpenseCategory>;
    selectedExpenseId: number;
}

export function ExpensesPage(): JSX.Element {
    const {tab} = useParams<{tab}>();

    return (
        <ExpensesPageCC
            selectedTab={tab as ExpensesTab}
            year={useNumberQueryParams(YEAR_PARAM)}
            month={useNumberQueryParams(MONTH_PARAM)}
        />
    );
}

class ExpensesPageCC extends React.Component<ExpensesPageProps, ExpensesPageState> {
    private keyboardElement: NumKeyboard;
    private cachedYearExpensesMap: Map<number, Array<Expense>>;

    constructor(props: ExpensesPageProps) {
        super(props);
        this.cachedYearExpensesMap = new Map<number, Array<Expense>>();

        this.state = {
            isLoaded: false,
            expenses: [],
            yearExpenses: [],
            expenseCategories: [],
            selectedExpenseId: null
        };
    }

    public componentDidMount() {
        this.loadExpensesService();
    }

    public componentDidUpdate(prevProps: Readonly<ExpensesPageProps>) {
        const {month, year, selectedTab} = this.props;
        if (yearChanged() || monthChanged()) {
            this.setState({isLoaded: false});
            loadExpensesService({year, month}).then((response) => {
                this.setState({expenses: response.expenses, isLoaded: true});
            });
        }

        if (yearChanged() || tabChangedToYearSummary()) {
            this.loadExpensesYearServiceAndSetState(year);
        }

        function yearChanged(): boolean {
            return prevProps.year !== year;
        }
        function monthChanged(): boolean {
            return prevProps.month !== month;
        }
        function tabChangedToYearSummary(): boolean {
            return prevProps.selectedTab !== selectedTab && selectedTab === ExpensesTab.YearSummary;
        }
    }

    private loadExpensesService() {
        const {year, month, selectedTab} = this.props;
        this.setState({isLoaded: false});
        loadExpensesService({year, month})
            .then((response) => {
                this.setState({isLoaded: true, expenses: response.expenses, expenseCategories: response.categories});
            })
            .then(() => {
                if (selectedTab === ExpensesTab.YearSummary) {
                    this.loadExpensesYearServiceAndSetState(year);
                }
            });
    }

    private loadExpensesYearServiceAndSetState(year: number): void {
        const cachedYearExpenses: Array<Expense> = this.cachedYearExpensesMap.get(year);
        if (cachedYearExpenses) {
            this.setState({yearExpenses: cachedYearExpenses});
            return;
        }
        this.setState({isLoaded: false});
        loadExpensesService({year}).then((result) => {
            this.cachedYearExpensesMap.set(year, result.expenses);
            this.setState({isLoaded: true, yearExpenses: result.expenses});
        });
    }

    private insertExpenseService(expense: Expense): void {
        this.setState({isLoaded: false, selectedExpenseId: null});
        addExpenseService(expense).then((result) => (result.success ? this.loadExpensesService() : this.setState({isLoaded: true})));
    }

    public render(): JSX.Element {
        const {isLoaded, selectedExpenseId} = this.state;

        if (!isLoaded) {
            return <Loading />;
        }

        if (selectedExpenseId) {
            return (
                <mp-expenses-page>
                    <ToolsBar onBackCallback={() => this.setState({selectedExpenseId: null})} />
                    <div className="inputs-container">
                        <NumKeyboard
                            expenseCategory={this.state.expenseCategories.find(({id}) => id === selectedExpenseId)}
                            onAddClick={(expense) => this.insertExpenseService(expense)}
                            ref={(element) => (this.keyboardElement = element)}
                        />
                    </div>
                </mp-expenses-page>
            );
        }

        return (
            <mp-expenses-page>
                <TabsNavigation tabs={getNavigationTabs(this.props)} onTabClicked={() => this.setState({selectedExpenseId: null})} />
                {selectedExpenseId && <div>{selectedExpenseId}</div>}
                {this.renderView()}
            </mp-expenses-page>
        );
    }

    private renderView(): JSX.Element {
        const {expenses, yearExpenses, expenseCategories} = this.state;
        const {selectedTab, year, month} = this.props;

        if (selectedTab === ExpensesTab.YearSummary) {
            return <ExpensesSummary expenses={yearExpenses} expenseCategories={expenseCategories} title={year} />;
        }

        if (selectedTab === ExpensesTab.List) {
            return (
                <MonthExpensesList
                    expenses={this.state.expenses}
                    expenseCategories={this.state.expenseCategories}
                    onItemChange={() => this.loadExpensesService()}
                />
            );
        }

        if (selectedTab === ExpensesTab.MonthSummary) {
            return (
                <>
                    {
                        <ExpensesSummary
                            expenses={expenses}
                            expenseCategories={expenseCategories}
                            title={`${resolveMonthName(month - 1)} ${year}`}
                            onExpenseCategoryClick={(id) => this.setState({selectedExpenseId: id})}
                        />
                    }
                </>
            );
        }

        return null;
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'mp-expenses-page': unknown;
        }
    }
}
