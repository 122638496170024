import React, {useEffect, useState} from 'react';

export function Blink(params: {text: string; timeout?: number}): JSX.Element {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const {text, timeout = 500} = params;

    useEffect(() => {
        const intervalId: NodeJS.Timeout = setInterval(() => setIsVisible(!isVisible), timeout);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [isVisible]);

    return <span style={{visibility: isVisible ? 'visible' : 'hidden'}}>{text}</span>;
}
