import Axios, {AxiosError, AxiosResponse} from 'axios';
import {insertServiceError} from '../components/error/ServiceError';
import {toBoolean} from '../utils/converters';

const BASE_PATH: string = '/api';

type ServicePrefix = 'comp' | 'pages' | 'filmweb';
type Method = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

interface ServiceGetParams {
    data?: object;
    prefix?: ServicePrefix;
}

interface ServiceParams {
    data: object;
    prefix?: ServicePrefix;
}

interface Service {
    get: <T>(serviceName: string, options?: ServiceGetParams) => Promise<AxiosResponse<T>>;
    post: (serviceName: string, options: ServiceParams) => Promise<ServiceResponseWithId>;
    put: (serviceName: string, params: ServiceParams) => Promise<ServiceResponseWithAffectedRow>;
    patch: (serviceName: string, params: ServiceParams) => Promise<ServiceResponseWithAffectedRow>;
    delete: (serviceName: string, params: ServiceParams) => Promise<DeleteServiceResponse>;
}
export const service: Service = {
    get: (serviceName, options = {}) => loadServiceFactory({serviceName, data: options.data, prefix: options.prefix}),
    post: (serviceName, {data, prefix}) => insertServiceFactory({serviceName, data, prefix}),
    put: (serviceName, {data, prefix}) => updateServiceFactory({serviceName, data, prefix, method: 'PUT'}),
    patch: (serviceName, {data, prefix}) => updateServiceFactory({serviceName, data, prefix, method: 'PATCH'}),
    delete: (serviceName, {data, prefix}) => deleteServiceFactory({serviceName, data, prefix})
};

function resolveParams(data: object): string {
    if (data == null) {
        return null;
    }
    const entries: Array<[string, unknown]> = Object.entries(data);
    if (entries.length === 0) {
        return null;
    }

    const paramsArray: Array<string> = [];
    entries.forEach((entry) => {
        const key: string = entry[0];
        const value: unknown = entry[1];
        if (key != null && key !== '' && value != null) {
            paramsArray.push(`${key}=${value}`);
        }
    });
    return `?${paramsArray.join('&')}`;
}

interface ServiceFactoryParams {
    serviceName: string;
    prefix?: ServicePrefix;
    data?: unknown;
    method?: Method;
}

export interface ServiceResponseWithError<T> {
    error?: AxiosError;
    data: T;
}

export interface ServiceResponseWithId {
    success: boolean;
    id: number;
}

// todo
export interface UpdateServiceResponseWithAffectedRowDTO {
    success: string;
    message?: string;
    row_count: string;
}

export interface ServiceResponseWithAffectedRow {
    success: boolean;
    message?: string;
    rowCount: number;
}

export interface DeleteServiceResponse {
    success: boolean;
    deletedItems: number;
}

function loadServiceFactory<T>(param: Pick<ServiceFactoryParams, 'serviceName' | 'data' | 'prefix'>): Promise<AxiosResponse<T>> {
    const {serviceName, data, prefix = 'pages'} = param;
    const params: string = resolveParams(data as object);
    const serviceFullName: string = createServiceFullName(serviceName, prefix);
    return resolveAxiosMethod<AxiosResponse<T>>('GET', serviceFullName, undefined, params)
        .then((result: AxiosResponse<T>) => validateServiceName(result, serviceName, prefix))
        .catch((error) => {
            insertServiceError(error);
            return {data: []} as AxiosResponse;
        });
}

function insertServiceFactory(param: Omit<ServiceFactoryParams, 'method'>): Promise<ServiceResponseWithId> {
    const {serviceName, data, prefix = 'pages'} = param;
    return Axios.post(createServiceFullName(serviceName, prefix), data)
        .then((result: AxiosResponse<ServiceResponseWithId>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<ServiceResponseWithId>) => {
            const id: number = Number(result.data.id);
            const success: boolean = toBoolean(result.data.success);
            const response: ServiceResponseWithId = {id, success};
            if (!success) {
                console.error(response);
                throw Error('Error: EC_100');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {id: null, success: false} as ServiceResponseWithId;
        });
}

function updateServiceFactory(param: ServiceFactoryParams): Promise<ServiceResponseWithAffectedRow> {
    const {serviceName, data, prefix = 'pages', method} = param;
    const serviceFullName: string = createServiceFullName(serviceName, prefix);

    return resolveAxiosMethod<AxiosResponse<UpdateServiceResponseWithAffectedRowDTO>>(method, serviceFullName, data)
        .then((result: AxiosResponse<UpdateServiceResponseWithAffectedRowDTO>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<UpdateServiceResponseWithAffectedRowDTO>) => {
            const rowCount: number = Number(result.data.row_count) || 0;
            const success: boolean = toBoolean(result.data.success);
            const message: string = result.data.message;
            const response: ServiceResponseWithAffectedRow = {success, message, rowCount};
            if (!success) {
                if (response.message) {
                    throw Error(response.message);
                }
                console.error(response);
                throw Error('Error: EC_200');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {rowCount: 0, success: false} as ServiceResponseWithAffectedRow;
        });
}

function resolveAxiosMethod<T>(method: Method, serviceName: string, data: unknown, params?: string): Promise<T> {
    switch (method) {
        case 'POST':
            return Axios.post(serviceName, data ?? params);
        case 'GET':
            return Axios.get(`${serviceName}${params ? params : ''}`);
        case 'PUT':
            return Axios.put(serviceName, data);
        case 'PATCH':
            return Axios.patch(serviceName, data);
        case 'DELETE':
            return Axios.delete(serviceName, {data});
        default:
            return null;
    }
}

function deleteServiceFactory(param: Omit<ServiceFactoryParams, 'method' | 'params'>): Promise<DeleteServiceResponse> {
    const {serviceName, data, prefix = 'pages'} = param;
    const serviceFullName: string = createServiceFullName(serviceName, prefix);

    return resolveAxiosMethod<AxiosResponse<DeleteServiceResponse>>('DELETE', serviceFullName, data)
        .then((result: AxiosResponse<DeleteServiceResponse>) => validateServiceName(result, serviceName, prefix))
        .then((result: AxiosResponse<DeleteServiceResponse>) => {
            const response: DeleteServiceResponse = result.data;
            if (!response.success) {
                console.error(response);
                throw Error('Error: EC_300');
            }
            return response;
        })
        .catch((error) => {
            insertServiceError(error);
            return {deletedItems: 0, success: false} as DeleteServiceResponse;
        });
}

export function createServiceFullName(serviceName: string, prefix: ServicePrefix): string {
    return `${BASE_PATH}/${prefix}.${serviceName}`;
}

function validateServiceName<T>(result: AxiosResponse<T>, serviceName: string, prefix: ServicePrefix): AxiosResponse<T> {
    if (typeof result.data === 'string' && result.data.startsWith('<!doctype')) {
        throw new Error(`Service "${createServiceFullName(serviceName, prefix)}" not found`);
    }
    return result;
}
