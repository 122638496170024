export enum ErrorCode {
    Unauthorised = 401,
    NotFound = 404,
    MethodNotAllowed = 405
}

export enum EventType {
    CLICK = 'click'
}

export enum KeyboardCode {
    Dot = 46
}

export enum Key {
    BackSpace = 8,
    Enter = 13,
    Delete = 46
}

export interface ServerResponse {
    success: boolean;
    description?: string;
}

export interface UserData {
    role: UserType;
    settings?: Settings;
    calendarDates?: Array<CalendarRawDate>;
}

export interface CalendarRawDate {
    id: number;
    isPublic: boolean;
    showYears: boolean;
    name: string;
    type: string;
    date: string;
    dateTo: string;
    timeFrom: string;
    timeTo: string;
    doneDate: string;
    repeat: string;
    isOwn: boolean;
    everyWeek: boolean;
}

export enum UserType {
    ADMIN = 'admin',
    LOGGED_USER = 'loggedUser',
    SUSPECT = 'suspect'
}

export interface FormInputRequiredParams {
    disabled: boolean;
}

export enum SettingsKey {
    headerItems = 'headerItems',
    homeItems = 'homeItems',
    moviesPageSearchResultShowId = 'moviesPageSearchResultShowId',
    moviesPageSearchIncludeCriticsRating = 'moviesPageSearchIncludeCriticsRating',
    bucketListPageVisibleDetails = 'bucketListPageVisibleDetails',
    shoppingListPageWordWheelCount = 'shoppingListPageWordWheelCount',
    workingHoursPagePayment = 'workingHoursPagePayment',
    workingHoursPageDefaultEmployerId = 'workingHoursPageDefaultEmployerId'
}

export interface Settings {
    [SettingsKey.headerItems]: Array<number>;
    [SettingsKey.homeItems]: Array<number>;
    [SettingsKey.moviesPageSearchResultShowId]: boolean;
    [SettingsKey.moviesPageSearchIncludeCriticsRating]: boolean;
    [SettingsKey.bucketListPageVisibleDetails]: boolean;
    [SettingsKey.shoppingListPageWordWheelCount]: number;
    [SettingsKey.workingHoursPagePayment]: number;
    [SettingsKey.workingHoursPageDefaultEmployerId]: number;
}
