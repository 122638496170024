import {service} from '@mp/common/api';
import {createEpisodeId, getAirDate} from '../utils/episodeHelper';
import {EpisodeIdWithDate, RawSeries, Series} from '../types';

interface LoadSeriesServiceResult {
    series: Array<RawSeries>;
}

export function loadSeriesService(): Promise<Array<Series>> {
    return service.get<LoadSeriesServiceResult>('series').then(({data}) => resolveEpisodes(data));
}

function resolveEpisodes(data: LoadSeriesServiceResult): Array<Series> {
    return data.series.map((e): Series => {
        const {season, episode, nextAirDate} = e;
        const episodeIdWithDate: EpisodeIdWithDate = getValidAirDate(nextAirDate, season, episode);

        return {
            ...e,
            checkNewEpisodeState: episodeIdWithDate?.date ?? null,
            nextEpisodeIdWithDate: episodeIdWithDate
        };
    });
}

function getValidAirDate(nextAirDate: string, season: number, episode: number): EpisodeIdWithDate {
    const episodeIdWithDate: EpisodeIdWithDate = getAirDate(nextAirDate);
    if (episodeIdWithDate?.id === createEpisodeId({season, episode} as Series)) {
        return episodeIdWithDate;
    }
    return null;
}
