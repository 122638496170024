import React from 'react';

type Unit = 'zł' | 'kWh' | 'zł/kWh' | 'm3' | 'zł/m3';

const NUMBER_WITH_UNIT: string = 'numer-with-unit';

export function numberWithUnit(value: number, unit: Unit, toFixed: number = 0): JSX.Element {
    toFixed = toFixed < 0 ? 0 : toFixed;
    return (
        <span>
            <span className={`${NUMBER_WITH_UNIT}-value`}>{value.toFixed(toFixed)} </span>
            <span className={`${NUMBER_WITH_UNIT}-unit`} dangerouslySetInnerHTML={{__html: resolveUnit(unit)}} />
        </span>
    );
}

function resolveUnit(unit: Unit): string {
    switch (unit) {
        case 'm3':
            return '<span>m<sup>3</sup></span>';
        case 'zł/m3':
            return '<span>zł/m<sup>3</sup></span>';
        default:
            return unit + '';
    }
}
