import './_inCinemaMoviesTab.scss';
import React, {useState} from 'react';
import {Modal} from '@mp/common/modals/Modal';
import {MovieDetailsComponent} from './MovieDetailsComponent';
import {MovieRow} from '../common/MovieRow';
import {Movie} from './types';

const MP_IN_CINEMA_MOVIES_TAB: 'mp-in-cinema-movies-tab' = 'mp-in-cinema-movies-tab';

export function InCinemaView({movies}: {movies: Array<Movie>}) {
    const [selectedMovie, setSelectedMovie] = useState<Movie>(null);

    function setDetailsView(movie: Movie) {
        setSelectedMovie(movie);
    }

    return (
        <div className={MP_IN_CINEMA_MOVIES_TAB}>
            <div className={`${MP_IN_CINEMA_MOVIES_TAB}-grid`}>
                {movies.map((m) => (
                    <MovieRow key={m.id} movie={m} onTitleClick={setDetailsView} />
                ))}
            </div>

            {selectedMovie && (
                <Modal title="Film" handleClose={() => setSelectedMovie(null)}>
                    <MovieDetailsComponent fid={selectedMovie.fid} title={selectedMovie.titlePl} />
                </Modal>
            )}
        </div>
    );
}
