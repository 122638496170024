import {service, ServiceResponseWithId} from '@mp/common/api';
import {DiscountCard} from '../types';

const DISCOUNT_CARDS_SERVICE_NAME: string = 'discountCards';

interface DiscountCardDTO {
    id: number;
    title: string;
    number: number;
    image: string;
    sortOrder: number;
}

export function loadDiscountCardsService(): Promise<Array<DiscountCard>> {
    return service.get<Array<DiscountCardDTO>>(DISCOUNT_CARDS_SERVICE_NAME).then((result) => result.data);
}

export function addDiscountCardService(data: Pick<DiscountCard, 'title' | 'number' | 'sortOrder'>): Promise<ServiceResponseWithId> {
    return service.post(DISCOUNT_CARDS_SERVICE_NAME, {data});
}
