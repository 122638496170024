export enum ScoreFactor {
    Normal = 1,
    Double = 2,
    Triple = 3
}

export interface Score {
    value: number;
    factor: ScoreFactor;
}

export interface Round {
    scoreValue1: number;
    scoreValue2: number;
    scoreValue3: number;
    scoreDisplay1: string;
    scoreDisplay2: string;
    scoreDisplay3: string;
    points: number;
    isFailed: boolean;
}

interface DartScoreHistoryEntry {
    id: string;
    score: Score;
}

export interface DartsGameData {
    playersIds: Array<string>;
    selectedPlayerId: string;
    history: DartScoreHistory;
    isVictory: boolean;
}

export type DartScoreHistory = Array<DartScoreHistoryEntry>;
