import React from 'react';
import {ReactComponent as AppleWholeSolid} from './apple-whole-solid.svg';
import {ReactComponent as AtomSolid} from './atom-solid.svg';
import {ReactComponent as BanSolid} from './ban-solid.svg';
import {ReactComponent as BreadSliceSolid} from './bread-slice-solid.svg';
import {ReactComponent as CandyCaneSolid} from './candy-cane-solid.svg';
import {ReactComponent as CatSolid} from './cat-solid.svg';
import {ReactComponent as CheeseSolid} from './cheese-solid.svg';
import {ReactComponent as DropletSolid} from './droplet-solid.svg';
import {ReactComponent as DrumstickBiteSolid} from './drumstick-bite-solid.svg';
import {ReactComponent as FishFinsSolid} from './fish-fins-solid.svg';
import {ReactComponent as HouseChimneySolid} from './house-chimney-solid.svg';
import {ReactComponent as MartiniGlassSolid} from './martini-glass-solid.svg';
import {ReactComponent as PencilSolid} from './pencil-solid.svg';
import {ReactComponent as PepperHotSolid} from './pepper-hot-solid.svg';
import {ReactComponent as PillsSolid} from './pills-solid.svg';
import {ReactComponent as QuestionSolid} from './question-solid.svg';
import {ReactComponent as SeedlingSolid} from './seedling-solid.svg';
import {ReactComponent as ShirtSolid} from './shirt-solid.svg';
import {ReactComponent as SnowflakeRegular} from './snowflake-regular.svg';
import {ReactComponent as TagSolid} from './tag-solid.svg';
import {ReactComponent as ToiletPaperSolid} from './toilet-paper-solid.svg';
import {ReactComponent as TvSolid} from './tv-solid.svg';

export enum Icon {
    AppleWhole = 'apple-whole-solid',
    Atom = 'atom-solid',
    Ban = 'ban-solid',
    BreadSlice = 'bread-slice-solid',
    CandyCane = 'candy-cane-solid',
    Cat = 'cat-solid',
    Cheese = 'cheese-solid',
    Droplet = 'droplet-solid',
    DrumstickBite = 'drumstick-bite-solid',
    FishFins = 'fish-fins-solid',
    HouseChimney = 'house-chimney-solid',
    MartiniGlass = 'martini-glass-solid',
    Pencil = 'pencil-solid',
    PepperHot = 'pepper-hot-solid',
    Pills = 'pills-solid',
    Question = 'question-solid',
    Seedling = 'seedling-solid',
    Shirt = 'shirt-solid',
    Snowflake = 'snowflake-regular',
    TV = 'tv-solid',
    Tag = 'tag-solid',
    ToiletPaper = 'toilet-paper-solid'
}

const MAP: Map<Icon, JSX.Element> = new Map<Icon, JSX.Element>([
    [Icon.AppleWhole, <AppleWholeSolid />],
    [Icon.Atom, <AtomSolid />],
    [Icon.Ban, <BanSolid />],
    [Icon.BreadSlice, <BreadSliceSolid />],
    [Icon.CandyCane, <CandyCaneSolid />],
    [Icon.Cat, <CatSolid />],
    [Icon.Cheese, <CheeseSolid />],
    [Icon.Droplet, <DropletSolid />],
    [Icon.DrumstickBite, <DrumstickBiteSolid />],
    [Icon.FishFins, <FishFinsSolid />],
    [Icon.HouseChimney, <HouseChimneySolid />],
    [Icon.MartiniGlass, <MartiniGlassSolid />],
    [Icon.Pencil, <PencilSolid />],
    [Icon.PepperHot, <PepperHotSolid />],
    [Icon.Pills, <PillsSolid />],
    [Icon.Question, <QuestionSolid />],
    [Icon.Seedling, <SeedlingSolid />],
    [Icon.Shirt, <ShirtSolid />],
    [Icon.Snowflake, <SnowflakeRegular />],
    [Icon.TV, <TvSolid />],
    [Icon.Tag, <TagSolid />],
    [Icon.ToiletPaper, <ToiletPaperSolid />]
]);

export function Svg(props: {name: Icon}): JSX.Element {
    return MAP.get(props.name) ?? null;
}
