import './_shoppingListPage.scss';
import {useParams} from 'react-router-dom';
import {AxiosError} from 'axios';
import React from 'react';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {isNotEmpty} from '@mp/common/utils/array';
import {ShoppingListTab} from '@mp/route';
import {
    loadCategoriesService,
    loadItemsService,
    loadShoppingListItemsService,
    LoadShoppingListServiceResult,
    truncateListItemsService
} from '../services';
import {TabAction, TabsNavigation} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {ShoppingCategory, ShoppingItem, ShoppingListItem} from '../types';
import {MP_SHOPPING_LIST_PAGE} from '../../../intrinsicElements';
import {ShoppingItemsListView} from './ShoppingItemsListView';
import {ServiceResponseWithError} from '@mp/common/api';
import {getNavigationTabs} from './utils/getNavigationTabs';
import {ShoppingItemsSearch} from './ShoppingItemsSearch';
import {getAppSettings, isAdmin} from '../../../global';

const SECONDS_TO_REFRESH_INIT: number = 60;

interface ShoppingState {
    items: Array<ShoppingItem>;
    listItems: Array<ShoppingListItem>;
    commonListItems: Array<ShoppingListItem>;
    otherListItems: Array<ShoppingListItem>;
    categories: Array<ShoppingCategory>;
    isLoaded: boolean;
    error?: AxiosError;
    showDeleteBoughtItemsModal: boolean;
    secondsToRefresh: number;
}

interface ShoppingPageProps {
    selectedTab: ShoppingListTab;
}

export function ShoppingListPage() {
    const {tab} = useParams<{tab}>();
    return <ShoppingListPageCC selectedTab={tab as ShoppingListTab} />;
}

class ShoppingListPageCC extends React.Component<ShoppingPageProps, ShoppingState> {
    private readonly maxSearchResult: number;
    private readonly secondsIntervalId: number = null;

    constructor(props: ShoppingPageProps) {
        super(props);

        this.onItemListUpdate = this.onItemListUpdate.bind(this);
        this.updateTimer = this.updateTimer.bind(this);

        this.state = {
            items: [],
            listItems: [],
            commonListItems: [],
            otherListItems: [],
            categories: [],
            isLoaded: false,
            showDeleteBoughtItemsModal: false,
            secondsToRefresh: SECONDS_TO_REFRESH_INIT
        };

        this.maxSearchResult = getAppSettings().shoppingListPageWordWheelCount;
        this.secondsIntervalId = window.setInterval(this.updateTimer, 1_000);
    }

    private updateTimer(): void {
        const {secondsToRefresh} = this.state;

        if (secondsToRefresh > 0) {
            this.setState({secondsToRefresh: secondsToRefresh - 1});
        } else {
            this.setState({secondsToRefresh: SECONDS_TO_REFRESH_INIT}, () => this.onItemListUpdate());
        }
    }

    public componentDidMount() {
        Promise.all([loadItemsService(), loadShoppingListItemsService(), loadCategoriesService()]).then((values) => {
            const [items, listItems, categories]: [
                ServiceResponseWithError<Array<ShoppingItem>>,
                ServiceResponseWithError<LoadShoppingListServiceResult>,
                ServiceResponseWithError<Array<ShoppingCategory>>
            ] = values;

            const error: AxiosError = items.error || listItems.error || categories.error;
            this.setState({
                items: items.data,
                listItems: listItems.data.listItems,
                commonListItems: listItems.data.commonListItems,
                otherListItems: listItems.data.otherListItems,
                categories: categories.data,
                isLoaded: true,
                error
            });
        });
    }

    public componentWillUnmount() {
        clearTimeout(this.secondsIntervalId);
    }

    public render() {
        const {selectedTab} = this.props;
        const {error, isLoaded, commonListItems, otherListItems} = this.state;

        if (error) {
            console.error(error);
            return <div>Unknown error</div>;
        }
        if (isLoaded) {
            const items: Array<ShoppingListItem> = selectedTab === ShoppingListTab.COMMON ? commonListItems : otherListItems;

            const tabActions: Array<TabAction> = [];
            if (isAdmin() && this.areExistBoughtItems()) {
                tabActions.push({
                    name: <div style={{lineHeight: '39px', margin: '0 8px'}}>(Wyczyść)</div>,
                    onClick: () => this.setState({showDeleteBoughtItemsModal: true})
                });
            }

            return (
                <MP_SHOPPING_LIST_PAGE>
                    <div className={MP_SHOPPING_LIST_PAGE}>
                        <TabsNavigation
                            tabs={getNavigationTabs({
                                selectedTab,
                                commonListItemsCount: commonListItems.length,
                                otherListItemsCount: otherListItems.length
                            })}
                            actions={tabActions}
                        />
                        <div className={MP_SHOPPING_LIST_PAGE + '-refresh'}>{`Odświeżenie listy za: ${this.state.secondsToRefresh}`}</div>
                        <ShoppingItemsSearch
                            items={this.state.items}
                            listItems={this.state.listItems}
                            maxSearchResult={this.maxSearchResult}
                            onItemListUpdate={this.onItemListUpdate}
                        />
                        <ShoppingItemsListView items={items} categories={this.state.categories} onItemListUpdate={this.onItemListUpdate} />
                    </div>
                    {this.renderDeleteBoughtItemsModal()}
                </MP_SHOPPING_LIST_PAGE>
            );
        }

        return <div>Loading...</div>;
    }

    private renderDeleteBoughtItemsModal(): JSX.Element {
        if (this.state.showDeleteBoughtItemsModal) {
            return (
                <ConfirmModal
                    title={'Usuwanie kupionych przedmiotów'}
                    question={'Czy na pewno skasować kupione przedmioty z listy?'}
                    handleConfirm={() =>
                        truncateListItemsService().then(() =>
                            this.onItemListUpdate().then(() => this.setState({showDeleteBoughtItemsModal: false}))
                        )
                    }
                    handleClose={() => this.setState({showDeleteBoughtItemsModal: false})}
                />
            );
        }
    }

    private areExistBoughtItems(): boolean {
        const {commonListItems, otherListItems} = this.state;
        return isNotEmpty(commonListItems.filter((f) => f.done).concat(otherListItems.filter((f) => f.done)));
    }

    private onItemListUpdate(): Promise<void> {
        return loadShoppingListItemsService().then((listItems) =>
            this.setState({
                listItems: listItems.data.listItems,
                commonListItems: listItems.data.commonListItems,
                otherListItems: listItems.data.otherListItems,
                isLoaded: true
            })
        );
    }
}
